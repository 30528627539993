import gql from "graphql-tag";

export const CREATE_LEAVE_REQUEST = gql`
  mutation BookLeave($data: BookLeaveInput!) {
    bookLeave(data: $data) {
      id
      status
      days_off
      leave_policy_id
      user_id
      manager_id
      country
      days
      year
      employee_detail
      file
    }
  }
`;

export const CREATE_BULK_LEAVE_REQUEST = gql`
  mutation CreateBulkBookLeave($data: BulkBookLeaveInput!) {
    createBulkBookLeave(data: $data) {
      success
      userCount
      failedUsers
    }
  }
`;

export const EDIT_LEAVE_REQUEST = gql`
  mutation UpdateBookedLeave($data: updateBookedLeaveInput!) {
    updateBookedLeave(data: $data) {
      id
      status
      days_off
      leave_policy_id
      user_id
      manager_id
      country
      days
      year
      employee_detail
      file
    }
  }
`;

export const SOFT_DELETE_LEAVE_REQUEST = gql`
  mutation DeleteBookedLeave($deleteBookedLeaveId: ID!) {
    deleteBookedLeave(id: $deleteBookedLeaveId) {
      id
      status
      days_off
      leave_policy_id
      user_id
      manager_id
      country
      days
      year
      employee_detail
      file
    }
  }
`;
