<template>
  <div>
    <ul class="flex flex-wrap pt-4 pb-1.5 gap-10">
      <li v-for="(item, index) in items" :key="index">
        <RouterLink :to="item.to" :class="getItemClasses(item)" class="truncate">
          <span class="N700">{{ item.label }}</span>
        </RouterLink>
      </li>
    </ul>
    <HorizontalLine />
  </div>
</template>

<script setup lang="ts">
import { RouterLink } from "vue-router";
import HorizontalLine from '@/common/ui-kit/HorizontalLine.vue';

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  activeItem: {
    type: String,
    default: "",
  },
});

const getItemClasses = (item: { to: string; }) => {
  return {
    "border-b-2 pb-2 border-red-500 bg-[#F9E0D7] p-3 N700 rounded-tl-md rounded-tr-md":
      item.to === props.activeItem,
    "P200 N800 5": true,
  };
};
</script>
