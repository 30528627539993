<template>
    <div>
        <!-- Modal -->
        <div v-if="open"
            class="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50 closeModal overflow-y-auto"
            @click="closeModalOutside">
            <div class="BG0 rounded shadow-md m-10">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    open: {
        type: Boolean,
        defuault: false
    },
    persist: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(["close"])


const closeModal = () => {
    if (!props.persist) {
        emit("close");
    }
};

const closeModalOutside = (event: MouseEvent) => {
    if (event.target instanceof HTMLElement && event.target.classList.contains('closeModal')) {
        closeModal();
    }
};
</script>