import leaveRoutes from "@/router/leaveRoutes";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import PageNotFound from "@/common/ui-kit/PageNotFound.vue";
import { fetchToken, getToken, getUserid, isLoggedIn, logout, setToken } from "../services/auth";
import { encodeUrlPath } from "@/helpers/utils";
import { useStore } from "@/store";

const route: Array<RouteRecordRaw> = [
  {
    path: "/logout",
    name: "logout",
    alias: "/logout",
    component: () => "",
    meta: { auth: false },
    beforeEnter: (_to, _from, next) => {
      logout();
      next("/login");
    },
  },
  {
    path: "/login",
    name: "login",
    alias: "/login",
    component: () =>
      (window.location.href = `${process.env.VUE_APP_MAIN_PAGE_URL}login`),
    meta: { auth: false },
  },
  {
    path: "/legacy",
    name: "legacy",
    component: () =>
      (window.location.href = `${
        process.env.VUE_APP_ERP_API
      }/auth?token=${getToken()}`),
    meta: { auth: true },
    beforeEnter: (_to, _from, next) => {
      if (isLoggedIn()) {
        window.location.href = `${
          process.env.VUE_APP_ERP_API
        }/auth?token=${getToken()}`;
      } else {
        next();
      }
    },
  },
  {
    path: "/user-profile",
    name: "user-profile",
    meta: { auth: true },
    /**
     * Redirects to the user's profile page.
     * @returns {void}
     */
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal`),
  },
  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: { auth: true },
  },

  /** End of Auth routes */
];

const env = process.env;
const isLeaveRoutesEnabled = !env.VUE_APP_LEAVE_ROUTES_ENABLED;

export const routes = route.concat(isLeaveRoutesEnabled ? leaveRoutes : []);

// Create the router
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async(to, _from, next) => {
  if (getToken()) return next();

    const response = await fetchToken();

  if (response.app_token) {
    const appToken = response.app_token;
    const loginHint = response.login_hint as string;
    setToken(appToken);
    useStore()?.setLoginHint(loginHint);
    
    next();
  } else if (to.meta.auth && !isLoggedIn()) {
    const redirect = `${window.location.protocol}//${window.location.host}`;
        window.location.href = `${env.VUE_APP_SSO}/v2/login?redirect-to=${redirect}`; // to change this to new dashboard after every service complete new implementation

  } else {
    next();
  }
});

export default router;
