<template>
  <template
    v-if="!env.VUE_APP_HR_DASHBOARD && leavesPerm.view_hr_employee_info"
  >
    <!-- ONBOARDING CARD -->
    <OnboardingCardComponent
      v-if="tableData.length === 0 && showOnboarding"
      class="z-10"
      @btncall="setLocal"
      v-show="showOnboarding"
      title="Welcome to Employee Leave Manager"
      content="On this page you can manage employee leave informations. Get started now!."
    />
    <div>
      <!-- HR manager overview -->
      <CategorySkeleton v-if="queryLoading" />
      <div class="relative">
        <div
          class="
            flex
            items-center
            gap-5
            mt-5
            overflow-x-auto overflow-y-hidden
            custom-scrollbar
          "
          ref="scrollContainer"
        >
          <div
            v-for="leaveCount in data.listHrLeavesInfo.on_leave_count"
            :key="leaveCount.leave_type.title"
            class="
              flex
              items-center
              justify-around
              border border-gray-300
              BG0
              rounded-lg
              p-5
              gap-2
              lg:w-1/4
              w-fit
              h-[6.25rem]
            "
          >
            <component :is="getLeaveTypeIcon(leaveCount.leave_type.title)" />
            <div>
              <p class="N700 truncate">
                {{ leaveCount.leave_type.title }}
              </p>
              <h3 class="text-center">
                {{ formatLeaveCount(leaveCount.count) }}
              </h3>
            </div>
          </div>
        </div>

        <button
          class="
            absolute
            h-20
            w-20
            -top-2
            left-0
            mt-5
            ml-2
            bg-opacity-0 bg-white
            rounded-full
            p-2
          "
          @mouseover="startScroll('left')"
          @mouseleave="stopScroll"
        ></button>
        <button
          class="
            absolute
            h-20
            w-20
            -top-2
            right-0
            mt-5
            mr-2
            bg-opacity-0 bg-white
            rounded-full
            p-2
          "
          @mouseover="startScroll('right')"
          @mouseleave="stopScroll"
        ></button>
      </div>

      <!-- HR manager table -->
      <div class="flex items-center justify-between mt-5">
        <h3 class="py-2">Employee leave information</h3>

        <div class="flex gap-2 flex-row">
          <ButtonComponent
            data-cy="exportLeave"
            buttonLabel="Export Leave Report"
            variant="with_border"
            @click="[(hrCsvExportDialog = true)]"
          />
          <ButtonSkeleton v-if="queryLoading" />
          <ButtonComponent
            v-if="
              !queryLoading &&
              !env.VUE_APP_HR_DASHBOARD &&
              leavesPerm.manage_hr_employee_info
            "
            data-cy="bulkBookButton"
            class="shadow-md"
            buttonLabel="Bulk Leave"
            variant="primary"
            @click="handleBulkLeave"
          />
        </div>
      </div>

      <div
        class="
          flex flex-col
          items-center
          justify-between
          my-3
          lg:flex-row lg:gap-0
        "
      >
        <div class="flex items-center gap-2">
          <SelectFieldValue
            :showSearch="false"
            :showLabel="false"
            id="status"
            data-cy="filterField"
            class="BG0"
            style="width: 100%; height: 42px"
            placeholder="Filter"
            :options="['On-site', 'On leave']"
            v-model="selectedFilter"
          />
          <SearchComponent data-cy="search" @search="searchTableData" />
        </div>
      </div>

      <TableComponentVue
        :overflowXAuto="true"
        :headers="headers"
        :items="tableData"
        :itemsPerPage="10"
        :hiddePagination="true"
        :customScrollbar="true"
        emptyMessage="No employees found"
      >
        <template #employee_name="{ item }">
          <span
            @click="openLeaveEmployeeDialog(item)"
            class="flex flex-row items-center gap-4 cursor-pointer px-2 py-3"
          >
            <div class="w-[3rem]">
              <ProfileImageComponent
                :imgSrc="item.user?.profile_image ?? ''"
                :initials="
                  getUserNameInitials(item.user?.full_name ?? 'No Name')
                "
                widthSize="3rem"
                heightSize="3rem"
                text-size="text-2xl"
              />
            </div>
            {{ item.user?.full_name ?? "..." }}
          </span>
        </template>

        <template #total_entitlement="slotProps">
          <span class="flex item-center">
            {{
              Number.isInteger(slotProps.item.max_accrual)
                ? slotProps.item.max_accrual.toFixed(0)
                : slotProps.item.max_accrual.toFixed(2)
            }}
          </span>
        </template>

        <template #accrued="slotProps">
          <span class="flex item-center">
            {{
              Number.isInteger(slotProps.item.accrued_days)
                ? slotProps.item.accrued_days.toFixed(0)
                : slotProps.item.accrued_days.toFixed(2)
            }}
          </span>
        </template>

        <template #available="slotProps">
          <span class="flex item-center">
            {{
              Number.isInteger(slotProps.item.available_days)
                ? slotProps.item.available_days.toFixed(0)
                : slotProps.item.available_days.toFixed(2)
            }}
          </span>
        </template>

        <template #added="slotProps">
          <span class="flex item-center">
            {{
              Number.isInteger(slotProps.item.hr_addition)
                ? slotProps.item.hr_addition.toFixed(0)
                : slotProps.item.hr_addition.toFixed(2)
            }}
          </span>
        </template>

        <template #deducted="slotProps">
          <span class="flex item-center">
            {{
              Number.isInteger(slotProps.item.hr_deduction)
                ? slotProps.item.hr_deduction.toFixed(0)
                : slotProps.item.hr_deduction.toFixed(2)
            }}
          </span>
        </template>

        <template #carry_over="slotProps">
          <span class="flex item-center">
            {{
              Number.isInteger(slotProps.item.carry_over_days)
                ? slotProps.item.carry_over_days.toFixed(0)
                : slotProps.item.carry_over_days.toFixed(2)
            }}
          </span>
        </template>

        <template
          v-for="leaveType in leaveTypes"
          :key="leaveType.key"
          v-slot:[leaveType.slotName]="{ item }"
        >
          <span class="flex item-center">
            {{
              formatLeaveDays(item.leave_types_used.find((leave: LeaveHrTypes) => leave.leave_type &&
                leave.leave_type.title ===
                leaveType.title)?.days_used)
            }}
          </span>
        </template>

        <template #status="{ item }">
          <div
            class="
              flex
              item-center
              justify-end
              px-2
              truncate
              rounded-lg
              gap-x-2
              w-fit
            "
            :class="{
              'N900 bg-[#f2f2f2]': !item.on_leave,
              'G600 bg-[#D3F5F7]': item.on_leave,
            }"
          >
            <span>•</span>
            {{ item.on_leave ? "On leave" : "On-site" }}
          </div>
        </template>

        <template #action="{ item }">
          <div
            data-cy="viewDetails"
            @click="openLeaveEmployeeDialog(item)"
            class="cursor-pointer"
          >
            View
          </div>
        </template>
      </TableComponentVue>

      <div
        class="flex items-center justify-end py-8"
        v-if="count > variables.data.take"
      >
        <paginator
          :total-items="count"
          v-model="currentPage"
          :items-per-page="variables.data.take"
          :max-pages-shown="9"
          :on-click="loadMore"
          :key="currentPage"
        />
      </div>

      <!-- Loading state -->
      <div v-if="queryLoading && tableData.length === 0">
        <LoaderIconVue />
      </div>

      <!-- Dialogs for Bulk Leave Request -->
      <LeaveRequestDialog
        v-if="addDialog"
        v-model="addDialog"
        @closeDialog="addDialog = false"
        :maxAccrual="365"
        :leaveDaysUsed="365"
        :leaveDaysAccrued="365"
        :leaveDaysCarryOver="365"
        :listLeavesHolidays="ListLeavesHolidays"
        :editLeaveRequest="editMode"
        :handleBulkLeave="bulkMode"
        :leaveDaysCanNegate="true"
        :employee-id="viewedUserId"
      />

      <!-- Dialogs for Employee Information -->
      <LeavesEmployeeDialog
        v-model="employeeDialog"
        @closeDialog="employeeDialog = false"
        :openLeaveEmployeeDialog="employeeDialogMode"
        :employee-id="leave.id"
        :user-country="leave.country"
        :userLeaveDaysAvailable="leave.accrued_days"
        :userLeaveDaysEntitlement="leave.max_accrual"
        v-if="employeeDialog"
      />

      <!-- Dialogs for export CSV -->
      <LeaveHrCsvExportDialog
        v-model="hrCsvExportDialog"
        @closeDialog="hrCsvExportDialog = false"
        v-if="hrCsvExportDialog"
      />
    </div>
  </template>
  <div v-else-if="env.VUE_APP_HR_DASHBOARD">
    <VersionDisplay class="" />
  </div>
  <div class="flex flex-col" v-else>
    <div class="p-3 mx-auto mt-10"></div>
    <NotAuthorised class="" />
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, computed, watchEffect, watch } from "vue";
import { useStore } from "@/store";
import {
  EmployeeLeaveInfo,
  LeaveEmployee,
  LeaveHrTypes,
} from "@/types/hr-dashboard/leaveHrApprovals";
import { LeaveHoliday } from "@/types/leave-configuration/leaveHolidays";
import {
  getUserNameInitials,
  formatLeaveCount,
} from "@/helpers/leaveRequestFunctions";
import { useAccessStore } from "@/store/storeLeavesPermissions";
import {
  searchedQuery,
  setLocal,
  showOnboarding,
} from "@/helpers/book-leaves/leaveRequests";
import {
  formatLeaveDays,
  getFilteredByQuery,
  getFilteredByLeaveStatus,
  getValidNames,
  sortByFullName,
} from "@/helpers/hr-dashboard/hrManager";
import OnSiteUserIcon from "@/assets/svg-leave-types/on-site-user-icon.vue";
import MaternityUserIcon from "@/assets/svg-leave-types/maternity-user-icon.vue";
import OnLeaveUserIcon from "@/assets/svg-leave-types/on-leave-user-icon.vue";
import BereavementUserIcon from "@/assets/svg-leave-types/bereavement-user-icon.vue";
import SickLeaveUserIcon from "@/assets/svg-leave-types/sick-leave-user-icon.vue";
import CategorySkeleton from "@/common/ui-kit/loaderSkeletons/CategorySkeleton.vue";
import ButtonSkeleton from "@/common/ui-kit/loaderSkeletons/ButtonSkeleton.vue";
import LeavesEmployeeDialog from "@/reuseable/hr-dashboard/HrEmployeeLeaveRequest.vue";
import SelectFieldValue from "@/common/ui-kit/Inputs/SelectField.vue";
import LoaderIconVue from "@/common/ui-kit/loader/LoaderIcon.vue";
import TableComponentVue from "@/common/ui-kit/table/TableComponent.vue";
import paginator from "@/common/ui-kit/pagination/PaginationComponent.vue";
import VersionDisplay from "@/common/components/HelloWorld.vue";
import NotAuthorised from "@/common/components/NotAuthorized.vue";
import OnboardingCardComponent from "@/common/components/OnboardingCardComponent.vue";
import ProfileImageComponent from "@/common/components/ProfileImageComponent.vue";
import SearchComponent from "@/common/components/SearchComponent.vue";
import LeaveHrCsvExportDialog from "@/reuseable/hr-dashboard/HrCsvExport.vue";
import ButtonComponent from "@/common/ui-kit/button/ButtonComponent.vue";
import LeaveRequestDialog from "@/reuseable/book-leaves/LeaveRequestDialog.vue";
import { useQuery, useQueryLoading } from "@vue/apollo-composable";
import { leaveTypes, headers } from "@/constants";
import {
  GET_HR_EMPLOYEES_LISTS,
  GET_HR_EMPLOYEES_OVERVIEW,
} from "../../graphql/queries/hr-dashboard/getHrApprovals";

// Constants
const env = process.env;

// Reactive Variables
const Access = useAccessStore();
const permissions = Access.permissions?.settings?.leave_management;
const leavesPerm = permissions ?? {};

const employeeDialog = ref(false);
const employeeDialogMode = ref(false);
const addDialog = ref(false);
const editMode = ref(false);
const bulkMode = ref(false);
const ListLeaveEmployees = ref<LeaveEmployee[]>([]);
const selectedFilter = ref("On-site");
const hrCsvExportDialog = ref(false);
const queryLoading = useQueryLoading();
const store = useStore();
const viewedUserId = store.user.user_id?.toString();
const ListLeavesHolidays = ref<LeaveHoliday[]>([]);

const leave: LeaveHrTypes = reactive<LeaveHrTypes>({
  user: {
    employeeInfo: {
      organization: {
        country: "",
      },
    },
  },
  accrued_days: 0,
  available_days: 0,
  max_accrual: 0,
  id: "",
  user_id: "",
  country: "",
  leave_type: {
    title: "",
  },
});

// computed properties
const data = computed(() => {
  return {
    listHrLeavesInfo: {
      on_leave_count: ListLeaveEmployeesOverview.value.map((user) => ({
        count: user.count,
        leave_type: {
          title: user.leave_type.title,
          is_annual_default: user.leave_type.is_annual_default,
        },
      })),
    },
  };
});

type User = {
  full_name: string | null | undefined;
};

type Employee = {
  user: User;
  on_leave: boolean;
};

const tableData = computed<Employee[]>(() => {
  const employees = ListLeaveEmployees.value as Employee[];

  if (employees && Array.isArray(employees)) {
    if (searchedQuery.value !== "") {
      return getFilteredByQuery(employees, searchedQuery.value);
    } else {
      const filteredByLeaveStatus = getFilteredByLeaveStatus(
        employees,
        onLeave.value
      );
      const validNames = getValidNames(filteredByLeaveStatus);
      return sortByFullName(validNames);
    }
  }

  return [];
});

// functions
const close = () => {
  editMode.value = false;
  employeeDialog.value = false;
};

watchEffect(() => {
  if (!employeeDialog.value) {
    close();
  }
});

const  getLeaveTypeIcon = (leaveTypeTitle: string) => {
  switch (leaveTypeTitle) {
    case "On-site Employee":
      return OnSiteUserIcon;
    case "On Leave request":
      return OnLeaveUserIcon;
    case "Annual Leave":
      return OnSiteUserIcon;
    case "Sick Leave":
      return SickLeaveUserIcon;
    case "Maternity Leave":
      return MaternityUserIcon;
    case "Bereavement Leave":
      return BereavementUserIcon;
    default:
      return OnLeaveUserIcon;
  }
};

function searchTableData(searchString: string) {
  searchedQuery.value = searchString.trim();
  variables.data.search =
    searchedQuery.value !== "" ? searchedQuery.value : null;
  leaveEmployees.refetch(variables);
}

const scrollContainer = ref<HTMLElement | null>(null);
let intervalId: number | undefined;
scrollContainer.value = document.getElementById("scrollContainer");

const startScroll = (direction: string) => {
  intervalId = setInterval(() => {
    if (scrollContainer.value) {
      const distance = direction === "left" ? -5 : 5;
      scrollContainer.value.scrollLeft += distance;
    }
  }, 20);
};

const stopScroll = () => {
  clearInterval(intervalId);
};



const handleBulkLeave = () => {
  bulkMode.value = true;
  addDialog.value = true;
  editMode.value = false;
};

const openLeaveEmployeeDialog = (item: LeaveHrTypes) => {
  if (item.user_id) {
    leave.id = item.user_id?.toString();
  }
  leave.country = item.user?.employeeInfo?.organization?.country ?? "";
  leave.accrued_days = item.accrued_days ?? "0";
  leave.max_accrual = item.max_accrual ?? "0";
  employeeDialog.value = true;
};

const onLeave = ref(false);
watch(selectedFilter, (newValue) => {
  onLeave.value = newValue === "On leave";
});

const variables = reactive({
  onLeave: onLeave.value,
  data: {
    take: 10,
    skip: 0,
    search: null as string | null,
    organization_id: store?.user?.organization,
  },
});

watch(onLeave, (newValue) => {
  variables.onLeave = newValue;
});

const variablesOverView = {
  data: {
    organization_id: store?.user?.organization,
  },
};

const leaveEmployeesOverview = useQuery(
  GET_HR_EMPLOYEES_OVERVIEW,
  variablesOverView
);
const ListLeaveEmployeesOverview = ref<EmployeeLeaveInfo[]>([]);

leaveEmployeesOverview.onResult((result) => {
  if (result.data?.countHrLeavesInfo) {
    const onLeaveCount = result.data.countHrLeavesInfo.on_leave_count;

    if (Array.isArray(onLeaveCount)) {
      const allowedTitles = [
        "On-site Employee",
        "On Leave request",
        "Annual Leave",
        "Sick Leave",
        "Paternity Leave",
        "Compensatory Leave",
        "Maternity Leave",
        "Bereavement Leave",
      ];

      const filteredArray = onLeaveCount.filter((item) =>
        allowedTitles.includes(item.leave_type.title)
      );

      ListLeaveEmployeesOverview.value = filteredArray.toSorted((a, b) => {
        const titleA = a.leave_type.title.toLowerCase();
        const titleB = b.leave_type.title.toLowerCase();

        if (titleA < titleB) return -1;
        if (titleA > titleB) return 1;
        return 0;
      });
    } else {
      ListLeaveEmployeesOverview.value = [];
    }
  }
});

const leaveEmployees = useQuery(GET_HR_EMPLOYEES_LISTS, variables, {
  fetchPolicy: "network-only",
});
const count = ref<number>(0); // Define the count variable
leaveEmployees.onResult((result) => {
  if (result.data?.listHrLeavesInfo) {
    ListLeaveEmployees.value = result.data.listHrLeavesInfo.users_list;
    count.value = result.data.listHrLeavesInfo.on_site_count || 0;
  } else {
    ListLeaveEmployees.value = [];
    count.value = 0;
  }
});

const currentPage = ref<number>(1);
const loadMore = (num: number) => {
  variables.data.skip = (num - 1) * variables.data.take;
  leaveEmployees.refetch(variables);
};
</script>
