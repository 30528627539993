<template>
  <GDialog
    v-model="hrCsvExportDialog"
    max-width="60rem"
    data-cy="hrCsvExportDialog"
  >
    <div class="py-4 px-4">
      <div class="flex items-center justify-between">
        <h3 class="H700 N900 relative" data-cy="dialogTitle">
          Leave Analysis
          <span class="absolute text-xs top-2 -right-8 font-normal N600"
            >CSV</span
          >
        </h3>
        <CloseCircle @click="$emit('closeDialog')" data-cy="closeButton" />
      </div>

      <div class="flex flex-col items-center justify-between py-2 lg:flex-row">
        <div class="w-fit flex gap-4">
          <InputFieldValue
            data-cy="datesInput"
            class="BG0"
            type="Date"
            :showlength="false"
            v-model="selectedFirstDate"
          />
          <InputFieldValue
            data-cy="datesInput"
            class="BG0"
            type="Date"
            :showlength="false"
            v-model="selectedLastDate"
            :min="selectedFirstDate"
          />
          <ButtonComponent
            v-if="!queryLoading"
            data-cy="loadData"
            variant="with_border"
            @click="loadData"
            :loading="queryLoading"
          >
            <GenerateSvg color="#8F95B2" />
          </ButtonComponent>
        </div>

        <!-- Form actions -->
        <div class="flex gap-4 p-2">
          <div
            v-if="!queryLoading"
            data-cy="exportLeave"
            class="
              p-2
              border border-gray-200
              BG0
              rounded-lg
              cursor-pointer
              hover:shadow-xl
              transition
              duration-300
              h-10
            "
          >
            <span class="relative cursor-pointer group">
              <CsvExportDataset
                @click="[(hrEmployeeCsvExportDialog = true)]"
                title="Open employee analysis"
                color="#8F95B2"
                data-cy="exportLeaveIcon"
              />
            </span>
          </div>
          <ButtonComponent
            class="flex gap-2 items-center"
            v-if="leavesPerm?.manage_hr_employee_info"
            title="Export leave analysis"
            data-cy="saveExportButton"
            @click="
              () => {
                $emit('closeDialog');
                exportCsv();
              }
            "
          >
            Export leave analysis
            <ExportCsv color="#fff" />
          </ButtonComponent>
        </div>
      </div>

      <div class="w-full lg:w-full rounded-lg">
        <TableComponentVue
          :overflowXAuto="true"
          :headers="headers"
          :items="ListLeaveCsvOverview"
          :itemsPerPage="7"
          :customScrollbar="true"
          emptyMessage="No leave data to export"
        >
          <template #category="slotProps">
            <span class="flex items-center cursor-pointer gap-2 py-3">
              {{ slotProps.item?.type_category }}
            </span>
          </template>
          <template #employee_count="slotProps">
            <span class="flex items-center">
              {{ formatLeaveCount(slotProps.item?.employee_count?.toFixed(0)) }}
            </span>
          </template>
          <template #hours_lost="slotProps">
            <span class="flex items-center">
              {{ formatLeaveCount(slotProps.item?.hours_lost?.toFixed(2)) }}
            </span>
          </template>
        </TableComponentVue>

        <div v-if="queryLoading">
          <LoaderIconVue />
        </div>
      </div>
    </div>

    <!-- Total Row -->
    <div
      class="flex gap-10 justify-end py-4 px-8 border-t border-gray-200"
      data-cy="totalRow"
    >
      <div class="px-1" data-cy="totalEmployeeCount">
        {{ formatLeaveCount(calculateTotalEmployeeCount) }} Requests
      </div>
      |
      <div class="px-1" data-cy="totalHoursLost">
        {{ calculateTotalHoursLost?.toFixed(2) }} Hours Lost
      </div>
    </div>

    <!-- Dialogs for export CSV -->
    <LeaveHrEmployeeCsvExportDialog
      v-model="hrEmployeeCsvExportDialog"
      @closeDialog="hrEmployeeCsvExportDialog = false"
      v-if="hrEmployeeCsvExportDialog"
      :startDate="firstDateNow"
      :endDate="endDateNow"
    />

    <AlertComponent :message-block="messageBlock" data-cy="leaveExportAlert" />
  </GDialog>
</template>

<script setup lang="ts">
import { ref, computed, watch, reactive } from "vue";
import {
  getCurrentDate,
  formatLeaveCount,
} from "@/helpers/leaveRequestFunctions";
import CloseCircle from "@/assets/svg-components/close-circle.vue";
import GenerateSvg from "@/assets/svg-components/generate-svg.vue";
import ButtonComponent from "@/common/ui-kit/button/ButtonComponent.vue";
import InputFieldValue from "@/common/ui-kit/Inputs/InputField.vue";
import {
  UseQueryReturn,
  useQuery,
  useQueryLoading,
} from "@vue/apollo-composable";
import { GET_LEAVES_CSV_OVERVIEW } from "@/graphql/queries/hr-dashboard/getHrApprovals";
import LeaveHrEmployeeCsvExportDialog from "@/reuseable/hr-dashboard/HrEmployeeExport.vue";
import CsvExportDataset from "@/assets/svg-components/csv-export-dataset.vue";
import ExportCsv from "@/assets/svg-components/export-csv.vue";
import TableComponentVue from "@/common/ui-kit/table/TableComponent.vue";
import LoaderIconVue from "@/common/ui-kit/loader/LoaderIcon.vue";
import { useAccessStore } from "@/store/storeLeavesPermissions";
import AlertComponent from "@/common/ui-kit/AlertComponent.vue";
import {
  LeaveCsvItem,
  LeaveCsvOverviewResponse,
} from "@/types/hr-dashboard/leaveHrApprovals";
import { useStore } from "@/store";

// Constants
const store = useStore();

// Reactive Variables
const Access = useAccessStore();
const permissions = Access.permissions?.settings?.leave_management;
const leavesPerm = permissions ?? {};
const loadDataClicked = ref(false);
const messageBlock = reactive({ open: false, severity: "", message: "" });
const queryLoading = useQueryLoading();
const hrEmployeeCsvExportDialog = ref(false);
const hrCsvExportDialog = ref(false);
const ListLeaveCsvOverview = ref<LeaveCsvItem[]>([]);

const headers = [
  { key: "category", label: "Category" },
  { key: "employee_count", label: "Requests" },
  { key: "hours_lost", label: "Hours" },
];

const currentDate = new Date();
const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1); // January is 0

const selectedFirstDate = ref<string>(
  firstDayOfYear.toISOString().split("T")[0]
);
const selectedLastDate = ref<string>(currentDate.toISOString().split("T")[0]);

// Watch selected dates
watch(selectedFirstDate, (newValue) => {
  if (newValue > selectedLastDate.value) {
    selectedLastDate.value = newValue;
  }
});

const exportCsv = (): void => {
  const header: string[] = ["Category", "Requests", "Hours Lost"];
  const dataRows: string[][] = ListLeaveCsvOverview.value.map((csvItem) => [
    csvItem.type_category,
    String(csvItem.employee_count),
    String(csvItem.hours_lost?.toFixed(2)),
  ]);

  const totalEmployeeCount = calculateTotalEmployeeCount.value;
  const totalHoursLost = calculateTotalHoursLost.value;

  const totalRow: string[] = [
    "Total",
    String(totalEmployeeCount),
    `${totalHoursLost?.toFixed(2)}`,
  ];
  const data: (string | number)[][] = [header, ...dataRows, totalRow];

  const csvContent = data.map((row) => row.join(",")).join("\n");
  const fileName = `leave_analysis_${selectedFirstDate.value}_to_${selectedLastDate.value}.csv`;

  const blob = new Blob([csvContent], { type: "text/csv" });

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const firstDateNow = ref<string>(firstDayOfYear.toISOString().split("T")[0]);
const endDateNow = ref<string>(currentDate.toISOString().split("T")[0]);

const loadData = (): void => {
  firstDateNow.value = selectedFirstDate.value || getCurrentDate();
  endDateNow.value = selectedLastDate.value || getCurrentDate();
  loadDataClicked.value = true;
};

const leaveCsvOverview: UseQueryReturn<
  LeaveCsvOverviewResponse,
  { startDate: string; endDate: string; organizationId: number }
> = useQuery(GET_LEAVES_CSV_OVERVIEW, {
  startDate: firstDateNow.value,
  endDate: endDateNow.value,
  organizationId: store?.user?.organization,
});

watch(loadDataClicked, () => {
  leaveCsvOverview.refetch();
});

leaveCsvOverview.onResult((result) => {
  if (result.data?.generateLeavesCsvByDate) {
    const newData = [...result.data.generateLeavesCsvByDate];

    newData.sort((a, b) => {
      const categoryA = a.type_category.toLowerCase();
      const categoryB = b.type_category.toLowerCase();

      if (categoryA < categoryB) return -1;
      if (categoryA > categoryB) return 1;
      return 0;
    });

    ListLeaveCsvOverview.value = newData;
  }
});

const calculateTotalEmployeeCount = computed(() => {
  return ListLeaveCsvOverview.value.reduce(
    (total, csvItem) => total + csvItem.employee_count,
    0
  );
});

const calculateTotalHoursLost = computed(() => {
  return ListLeaveCsvOverview.value.reduce(
    (total, csvItem) => total + csvItem.hours_lost,
    0
  );
});
</script>
