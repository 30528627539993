import gql from "graphql-tag";

export const CREATE_LEAVE_POLICY = gql`
  mutation CreateLeavePolicy($data: CreateLeavePolicyInput!) {
    createLeavePolicy(data: $data) {
      id
      title
      description
      leave_type_id
      max_accrual
      carry_over
      carry_over_expiry
      can_negate
      is_archived
      position_id
      user_id
    }
  }
`;

export const UPDATE_LEAVE_POLICY = gql`
  mutation UpdateLeavePolicy($data: UpdateLeavePolicyInput!) {
    updateLeavePolicy(data: $data) {
      id
      title
      description
      leave_type_id
      max_accrual
      carry_over
      carry_over_expiry
      can_negate
      is_archived
      position_id
      user_id
    }
  }
`;

export const TOGGLE_POLICY_STATUS = gql`
  mutation ArchiveLeavePolicy(
    $archiveLeavePolicyId: ID!
    $isArchived: Boolean!
  ) {
    archiveLeavePolicy(id: $archiveLeavePolicyId, is_archived: $isArchived) {
      id
      title
      description
      leave_type_id
      max_accrual
      carry_over
      carry_over_expiry
      can_negate
      is_archived
      position_id
      user_id
    }
  }
`;

export const REMOVE_LEAVE_POLICY_STATUS = gql`
  mutation DeleteLeavePolicy($deleteLeavePolicyId: ID!) {
    deleteLeavePolicy(id: $deleteLeavePolicyId) {
      id
      title
      description
      leave_type_id
      max_accrual
      carry_over
      carry_over_expiry
      can_negate
      is_archived
      position_id
      user_id
    }
  }
`;
