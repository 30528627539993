<template>
  <GDialog
    v-model="hrEmployeeCsvExportDialog"
    max-width="80rem"
    height="fit-content"
    data-cy="hrEmployeeCsvExportDialog"
  >
    <div class="py-4 px-4">
      <div class="flex items-center justify-between">
        <h3 class="H700 N900 relative" data-cy="dialogTitle">
          Employee Analysis
          <span class="absolute text-xs top-2 -right-8 font-normal N600"
            >CSV</span
          >
        </h3>
        <CloseCircle
          @click="
            $emit('closeDialog');
            resetForm();
          "
          data-cy="closeButton"
        />
      </div>

      <div class="flex items-center justify-between py-4">
        <p class="flex gap-2">
          <TimeIconVue color="#696969" />{{ formatDateShort(startDate) }} to
          {{ formatDateShort(endDate) }}
        </p>
        <div class="flex items-center gap-x-2 p-2">
          <SearchComponent
            data-cy="search"
            class="py-2"
            :clear="clear"
            @search="searchQueryData"
          />
          <ButtonComponent
            class="flex gap-2 items-center"
            v-if="leavesPermissions?.manage_hr_employee_info"
            title="Export employee analysis"
            data-cy="saveExportButton"
            @click="exportCsv"
          >
            Export employee analysis
            <ExportCsv color="#fff" />
          </ButtonComponent>
        </div>
      </div>

      <div class="w-full lg:w-full rounded-lg">
        <TableComponentVue
          :overflowXAuto="true"
          :headers="headers"
          :items="filteredLeaveCsvOverview"
          :itemsPerPage="10"
          :customScrollbar="true"
          emptyMessage="No employees data to export"
        >
          <template #employee_name="{ item }">
            <span class="flex items-center cursor-pointer gap-2 py-3">
              {{ item?.user_name }}
            </span>
          </template>
          <template #annual_used="{ item }">
            <span class="flex items-center">
              {{ item?.annual_used }}
            </span>
          </template>
          <template #sick_used="{ item }">
            <span class="flex items-center">
              {{ item?.sick_used }}
            </span>
          </template>
          <template #maternity_used="{ item }">
            <span class="flex items-center">
              {{ item?.maternity_used }}
            </span>
          </template>
          <template #bereavement_used="{ item }">
            <span class="flex items-center">
              {{ item?.bereavement_used }}
            </span>
          </template>
          <template #paternity_used="{ item }">
            <span class="flex items-center">
              {{ item?.paternity_used }}
            </span>
          </template>
          <template #compensatory_used="{ item }">
            <span class="flex items-center">
              {{ item?.compensatory_used }}
            </span>
          </template>
          <template #total_used="{ item }">
            <span class="flex items-center">
              {{ item?.total_used }}
            </span>
          </template>
        </TableComponentVue>

        <div v-if="queryLoading">
          <LoaderIconVue />
        </div>
        <AlertComponent
          :message-block="messageBlock"
          data-cy="leaveRequestAlert"
        />
      </div>
    </div>
  </GDialog>
</template>

<script setup lang="ts">
import { computed, defineProps, ref, toRefs, watch, watchEffect } from "vue";
import { messageBlock } from "@/helpers/book-leaves/leaveRequests";
import CloseCircle from "@/assets/svg-components/close-circle.vue";
import TimeIconVue from "@/assets/svg-components/time-icon.vue";
import ButtonComponent from "@/common/ui-kit/button/ButtonComponent.vue";
import SearchComponent from "@/common/components/SearchComponent.vue";
import { useQuery, useQueryLoading } from "@vue/apollo-composable";
import TableComponentVue from "@/common/ui-kit/table/TableComponent.vue";
import ExportCsv from "@/assets/svg-components/export-csv.vue";
import LoaderIconVue from "@/common/ui-kit/loader/LoaderIcon.vue";
import { GET_EMPLOYEE_LEAVES_CSV } from "@/graphql/queries/hr-dashboard/getHrApprovals";
import { useAccessStore } from "@/store/storeLeavesPermissions";
import AlertComponent from "@/common/ui-kit/AlertComponent.vue";
import { formatDateShort } from "@/helpers/leaveRequestFunctions";
import {
  LeaveTypeUsed,
  LeaveEmployeeCsvResponse,
} from "@/types/hr-dashboard/leaveHrApprovals";
import { useStore } from "@/store";

const store = useStore();

// Reactive Variables
const Access = useAccessStore();
const permissions = Access.permissions?.settings?.leave_management;
const leavesPermissions = permissions ?? {};

const props = defineProps<{
  startDate: string;
  endDate: string;
}>();
const { startDate, endDate } = toRefs(props);

const hrEmployeeCsvExportDialog = ref(false);
const queryLoading = useQueryLoading();
const ListLeaveCsvOverview = ref<
  {
    user_name: string;
    annual_used: string;
    sick_used: string;
    maternity_used: string;
    bereavement_used: string;
    paternity_used: string;
    compensatory_used: string;
    total_used: string;
  }[]
>([]);
const searchQuery = ref("");
const clear = ref(false);

const headers = [
  { key: "employee_name", label: "Employee name" },
  { key: "annual_used", label: "Annual leave taken" },
  { key: "sick_used", label: "Sick leave taken" },
  { key: "maternity_used", label: "Maternity leave taken" },
  { key: "bereavement_used", label: "Bereavement leave taken" },
  { key: "paternity_used", label: "Paternity leave taken" },
  { key: "compensatory_used", label: "Compensatory leave taken" },
  { key: "total_used", label: "Total used" },
];

const resetForm = (): void => {
  searchQuery.value = "";
  clear.value = false;
};

const closeDialog = (): void => {
  hrEmployeeCsvExportDialog.value = false;
  resetForm();
};

watchEffect(() => {
  if (!hrEmployeeCsvExportDialog.value) {
    closeDialog();
  }
});

const leaveCsvOverview = useQuery<LeaveEmployeeCsvResponse>(
  GET_EMPLOYEE_LEAVES_CSV,
  {
    startDate: startDate.value,
    endDate: endDate.value,
    organizationId: store?.user?.organization,
  }
);

watch([startDate, endDate], () => {
  leaveCsvOverview.refetch({
    startDate: startDate.value,
    endDate: endDate.value,
    organizationId: store?.user?.organization,
  });
});

const filteredLeaveCsvOverview = computed(() => {
  const query = searchQuery.value.trim().toLowerCase();
  return (
    ListLeaveCsvOverview.value?.filter(
      (entry) =>
        entry?.user_name &&
        typeof entry.user_name === "string" &&
        entry.user_name.toLowerCase().includes(query)
    ) || []
  );
});

const searchQueryData = (query: string): void => {
  searchQuery.value = query;
};

const formatDecimal = (value: number): string => {
  if (typeof value === "number" && !Number.isInteger(value)) {
    return value.toFixed(2);
  } else {
    return value.toString();
  }
};

const calculateTotalUsed = (leaveTypesUsed: LeaveTypeUsed[]): number => {
  let total = 0;
  leaveTypesUsed.forEach((leave) => {
    total += leave.hours_used;
  });
  return total;
};

leaveCsvOverview.onResult((result) => {
  if (result.data?.exportDaysUsedByUser) {
    const newData = [...result.data.exportDaysUsedByUser];
    ListLeaveCsvOverview.value = newData
      .filter(
        (item) =>
          item.user_name !== null &&
          item.user_name !== undefined &&
          item.user_name !== ""
      )
      .map((item) => ({
        user_name: item.user_name,
        annual_used: formatDecimal(
          item.leave_types_used.find(
            (leave) => leave.leave_type === "Annual Leave"
          )?.hours_used ?? 0
        ),
        sick_used: formatDecimal(
          item.leave_types_used.find(
            (leave) => leave.leave_type === "Sick Leave"
          )?.hours_used ?? 0
        ),
        maternity_used: formatDecimal(
          item.leave_types_used.find(
            (leave) => leave.leave_type === "Maternity Leave"
          )?.hours_used ?? 0
        ),
        bereavement_used: formatDecimal(
          item.leave_types_used.find(
            (leave) => leave.leave_type === "Bereavement Leave"
          )?.hours_used ?? 0
        ),
        paternity_used: formatDecimal(
          item.leave_types_used.find(
            (leave) => leave.leave_type === "Paternity Leave"
          )?.hours_used ?? 0
        ),
        compensatory_used: formatDecimal(
          item.leave_types_used.find(
            (leave) => leave.leave_type === "Compensatory Leave"
          )?.hours_used ?? 0
        ),
        total_used: formatDecimal(calculateTotalUsed(item.leave_types_used)),
      }));
  }
});

const exportCsv = (): void => {
  let csvContent = "Full Name";
  const leaveTypes = [
    "Annual leave taken",
    "Sick leave taken",
    "Maternity leave taken",
    "Bereavement leave taken",
    "Paternity leave taken",
    "Compensatory leave taken",
    "Total Used",
  ];
  leaveTypes.forEach((leaveType) => {
    csvContent += `,${leaveType}`;
  });
  csvContent += "\n";

  ListLeaveCsvOverview.value.forEach((entry) => {
    const {
      user_name,
      annual_used,
      sick_used,
      maternity_used,
      bereavement_used,
      paternity_used,
      compensatory_used,
      total_used,
    } = entry;
    csvContent += `${user_name}`;
    [
      annual_used,
      sick_used,
      maternity_used,
      bereavement_used,
      paternity_used,
      compensatory_used,
      total_used,
    ].forEach((hoursUsed) => {
      csvContent += `,${hoursUsed}`;
    });
    csvContent += "\n";
  });

  const fileName = `employee_analysis_${startDate.value}_to_${endDate.value}.csv`;
  const blob = new Blob([csvContent], { type: "text/csv" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
</script>
