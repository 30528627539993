<template>
  <svg
    width="41"
    height="29"
    viewBox="0 0 41 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40.4" height="5.77143" rx="2.88571" fill="#DD5928" />
    <rect
      y="11.543"
      width="40.4"
      height="5.77143"
      rx="2.88571"
      fill="#DD5928"
    />
    <rect
      y="23.0859"
      width="40.4"
      height="5.77143"
      rx="2.88571"
      fill="#DD5928"
    />
  </svg>
</template>

<script setup lang="ts"></script>
