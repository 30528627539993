<template>
  <template
    v-if="
      (!env.VUE_APP_HR_DASHBOARD && leavesPerm.view_hr_dashboard) ||
      approversPerm.view_leave_approvers
    "
  >
    <!-- ONBOARDING CARD -->
    <OnboardingCardComponent
      v-if="tableData.length === 0 && showOnboarding"
      class="z-10"
      @btncall="setLocal"
      v-show="showOnboarding"
      title="Welcome to HR Leave Manager"
      content="On this page you can manage staff leave requests and employee information. Get started now!."
    />
    <div>
      <!-- Table section -->
      <div
        class="
          flex flex-col
          items-center
          justify-between
          gap-5
          py-3
          lg:flex-row lg:gap-0
        "
      >
        <div class="flex flex-col items-center gap-5 lg:flex-row">
          <h3 class="py-2">{{ formatLeaveCount(countRequests) }} Requests</h3>
          <div class="flex items-center justify-center h-10">
            <span
              data-cy="pending"
              @click="handleStatusUpdate(RequestStatus.PENDING)"
              class="
                px-8
                py-2
                text-center
                bg-gray-100
                border border-gray-300
                cursor-pointer
                N700
                rounded-tl-md rounded-bl-md
                hover:bg-gray-100 hover:text-gray-600
              "
              :class="{
                'bg-white border-[#D8DAE5] p-2 px-4 N700 text-center border-r':
                  requestStatus == RequestStatus.PENDING,
              }"
              >Pending</span
            >
            <span
              data-cy="approve"
              @click="handleStatusUpdate(RequestStatus.APPROVED)"
              class="
                p-2
                px-8
                text-center
                N700
                bg-gray-100
                border-x-0 border-y border-[#D8DAE5]
                cursor-pointer
                hover:bg-gray-100 hover:text-gray-600
              "
              :class="{
                'bg-white N700 border-[#D8DAE5] p-2 px-4  text-center ':
                  requestStatus == RequestStatus.APPROVED,
              }"
              >Approved</span
            >
            <span
              data-cy="disapprove"
              @click="handleStatusUpdate(RequestStatus.DENIED)"
              class="
                p-2
                px-8
                text-center
                bg-gray-100
                border border-gray-300
                cursor-pointer
                N700
                rounded-tr-md rounded-br-md
                hover:bg-gray-100 hover:text-gray-600
              "
              :class="{
                'bg-white N700 border-[#D8DAE5] p-2 px-4 N700 text-center border-r':
                  requestStatus == RequestStatus.DENIED,
              }"
              >Disapproved</span
            >
          </div>
        </div>
        <SearchComponent data-cy="search" @search="searchTableData" />
      </div>

      <TableComponentVue
        :overflowXAuto="true"
        :headers="displayedHeaders"
        :items="tableData"
        :itemsPerPage="10"
        :hiddePagination="true"
        :customScrollbar="true"
        :emptyMessage="emptyMessage"
      >
        <template #employee_name="slotProps">
          <span
            @click="callEmployeeRequest(slotProps.item)"
            class="flex flex-row items-center gap-2 pt-3 pb-3 cursor-pointer"
          >
            <div class="w-[3rem]">
              <ProfileImageComponent
                :initials="getUserNameInitials(slotProps.item.user?.full_name)"
                :imgSrc="slotProps.item.user?.profile_image ?? ''"
                widthSize="3rem"
                heightSize="3rem"
                text-size="text-2xl"
              />
            </div>
            {{ slotProps.item.user?.full_name }}
          </span>
        </template>

        <template #duration="{ item }">
          <span class="flex items-center">
            {{ formatDateShort(item.first_last_days[0]) }} -
            {{ formatDateShort(item.first_last_days[1]) }}
          </span>
        </template>

        <template #leave_type="slotProps">
          <span class="flex items-center">
            {{ slotProps.item.leave_type }}
          </span>
        </template>

        <template #days_off="slotProps">
          <span class="flex items-center">
            {{ slotProps.item.days_off.toFixed(2) }}
            <span
              v-if="slotProps.item.carry_over_used > 0"
              title="Carry over"
              class="text-[0.7rem] O300"
            >
              &nbsp;+{{ slotProps.item.carry_over_used.toFixed(2) }}
            </span>
          </span>
        </template>

        <template #status="slotProps">
          <div
            data-cy="approvalStatus"
            class="flex items-center px-2 truncate rounded-lg gap-x-2 w-fit"
            :class="statusClass(slotProps.item.status)"
          >
            <span class="text-[1.3rem]">•</span>
            {{ statusText(slotProps.item.status) }}
          </div>
        </template>

        <template #action="slotProps">
          <div class="flex justify-start">
            <DropdownMenu
              :isLastItem="
                slotProps.displayedItems > 3
                  ? [
                      slotProps.displayedItems - 1,
                      slotProps.displayedItems - 2,
                      slotProps.displayedItems - 3,
                    ].includes(slotProps.index)
                  : [
                      slotProps.displayedItems - 1,
                      slotProps.displayedItems - 2,
                    ].includes(slotProps.index)
              "
              :bottom-class="'-mt-[10rem] absolute z-50'"
              data-cy="actions"
              @selectOption="(name: string) => handleSelectOption(slotProps.item, name)"
              :optionsList="dropdownOptions"
            />
          </div>
        </template>
      </TableComponentVue>

      <div
        class="flex items-center justify-end py-8"
        v-if="countRequests > variables.data.take"
      >
        <paginator
          :total-items="countRequests"
          v-model="currentPage"
          :items-per-page="variables.data.take"
          :max-pages-shown="9"
          :on-click="loadMore"
          :key="currentPage"
        />
      </div>

      <!-- Loading state -->
      <div v-if="queryLoading">
        <LoaderIconVue />
      </div>

      <!-- Dialog for approve and dissapprove -->
      <GDialog v-model="addDialog" max-width="45rem" v-if="addDialog">
        <div class="p-4">
          <div class="flex items-center justify-between">
            <h3 class="H600 N900 dark:text-">Time off Request</h3>
            <CloseCircle @click="close" />
          </div>
          <!-- User Profile -->
          <div
            class="
              flex
              items-stretch
              justify-start
              mt-[0.44rem]
              w-full
              gap-x-[0.62rem]
            "
          >
            <ProfileImageComponent
              :initials="
                GetEmployeeInformation?.full_name
                  ? getUserNameInitials(GetEmployeeInformation.full_name)
                  : ''
              "
              :imgSrc="GetEmployeeInformation?.profile_image ?? ''"
              widthSize="3rem"
              heightSize="3rem"
              text-size="text-2xl"
            />
            <div v-if="!isLoading" class="flex flex-col N700">
              <div class="flex text-base gap-x-2">
                <p class="font-medium">
                  {{ GetEmployeeInformation?.full_name }}
                </p>
                <p class="N500">|</p>
                <p class="font-normal">
                  {{ GetEmployeeInformation?.department_name }}
                </p>
              </div>
              <div>
                <p class="font-normal">
                  {{ GetEmployeeInformation?.position_name }}
                </p>
              </div>
            </div>
            <div v-else>Hold on...</div>
          </div>

          <!-- Overview -->
          <div class="flex flex-col md:flex-row items-stretch mt-4 gap-x-4">
            <div
              class="
                w-[35.1875rem]
                h-[24.5rem]
                bg-[#EDEFF5]
                rounded-lg
                flex flex-col
                p-4
                gap-y-4
              "
            >
              <div class="flex flex-col text-base gap-y-2">
                <p class="text-[16px] leading-6 text-gray-400 font-[500]">
                  Requested
                </p>
                <p class="N800">
                  {{ formatDateShort(leave.first_last_days[0]) }} -
                  {{ formatDateShort(leave.first_last_days[1]) }}
                </p>
              </div>
              <div class="flex flex-col text-base gap-y-2">
                <p class="text-[16px] leading-6 text-gray-400 font-[500]">
                  Available days
                </p>
                <p v-if="!isLoading" class="N800">
                  {{ GetAccruedDays?.leaveDaysAccrued.toFixed(2) }} days
                </p>
                <p v-else>Hold on...</p>
              </div>
              <div class="flex flex-col text-base gap-y-2">
                <p class="text-[16px] leading-6 text-gray-400 font-[500]">
                  For Annual leave
                </p>
                <p v-if="!isLoading" class="N800">
                  {{ GetAccruedDays?.max_accrual.toFixed(2) }} days
                </p>
                <p v-else>Hold on...</p>
              </div>
              <div class="flex flex-col text-base gap-y-2">
                <p class="text-[16px] leading-6 text-gray-400 font-[500]">
                  Policy
                </p>
                <p class="N800">
                  {{ (leave as LeaveHrApproval)["leave_type"] }}
                </p>
              </div>
            </div>

            <!-- Calendar -->
            <div
              class="
                w-full
                h-[24.5rem]
                sm:border-none
                py-4
                lg:py-0
                md:py-0
                border border-solid
                rounded-lg
                border-[#E6E8F0]
              "
            >
              <DatePicker
                :key="datePickerKey"
                :shortcuts="false"
                v-model="dateValue"
                as-single
                use-range
                no-input
                :disable-date="diabaledDate"
              />
            </div>
          </div>

          <!-- Description -->
          <div class="flex flex-col mt-4 N700">
            <div class="flex items-center justify-between">
              <div
                class="flex px-2 rounded-lg gap-x-2 w-fit"
                :class="{
                'bg-[#D3F5F7]  text-[#10899E]': (leave as LeaveHrApproval)['manager_approved'] === RequestStatus.APPROVED,
                'bg-[#f4c4c4]  text-[#b42f2f]': (leave as LeaveHrApproval)['manager_approved'] === RequestStatus.DENIED
              }"
              >
                <p>
                  <span
                    class="text-[1rem]"
                    v-if="leave.manager_approved === RequestStatus.APPROVED"
                    >• Approved by manager</span
                  >
                  <span
                    class="text-[1rem]"
                    v-else-if="leave.manager_approved === RequestStatus.DENIED"
                    >• Disapproved by manager</span
                  >
                </p>
              </div>
              <div class="flex items-center gap-4">
                <!-- File to download-->
                <a
                  v-if="leave.file && leave.file.length > 0"
                  data-cy="requestFile"
                  @click.prevent="downloadFile"
                  title="Click to download"
                  class="
                    flex
                    gap-2
                    border border-gray-300
                    bg-[#F4F6FA]
                    rounded
                    p-2
                    group
                    relative
                    transition
                    duration-300
                    hover:shadow-md hover:text-gray-600
                    cursor-pointer
                    w-60
                  "
                >
                  <DownloadIcon data-cy="requestFileIcon" />
                  <span class="truncate">{{ getFileName(leave.file) }}</span
                  >{{ leave.file.slice(-4) }}
                </a>

                <!-- chat button -->
                <span
                  data-cy="commentButton"
                  class="relative"
                  title="Request chats"
                  @click="toggleChat"
                >
                  <ConversationIcon data-cy="commentIcon" /><span
                    class="
                      absolute
                      inline-block
                      w-6
                      h-6
                      text-center text-white
                      bg-red-500
                      rounded-full
                      -top-4
                      -right-1
                      N700
                    "
                    v-if="filteredComments && filteredComments.length > 0"
                    >{{ filteredComments.length }}</span
                  >
                </span>
              </div>
            </div>

            <!-- Conversations -->
            <div v-show="isChatVisible" class="p-4 overflow-y-auto h-fit">
              <template v-if="filteredComments && filteredComments.length > 0">
                <div
                  data-cy="commentCard"
                  v-for="comment in filteredComments"
                  :key="comment.id"
                  class="
                    relative
                    p-2
                    my-2
                    transition
                    duration-300
                    border-b border-gray-200
                    group
                    hover:shadow-md
                  "
                >
                  <div class="flex items-center justify-between">
                    <div class="flex items-center gap-2">
                      <ProfileImageComponent
                        :initials="
                          getUserNameInitials(comment?.user?.full_name)
                        "
                        :imgSrc="comment?.user?.profile_image ?? ''"
                        widthSize="2rem"
                        heightSize="2rem"
                        text-size="text-md"
                      />
                      <p class="N900">
                        {{ comment?.user?.full_name || "Unknown User" }}
                      </p>
                    </div>
                    <p class="text-sm N600">
                      {{
                        formatDateShort(comment?.timestamp) || "Unknown Date"
                      }}
                    </p>
                  </div>
                  <p class="ml-10 N700">
                    {{ comment?.message || "No message available" }}
                  </p>
                </div>
              </template>
              <template v-else>
                <p data-cy="noComments" class="N700">
                  No touch point comments available
                </p>
              </template>
            </div>
          </div>

          <div class="pt-4">
            <MultiLineInputBox
              data-cy="commentField"
              label=""
              :showlength="true"
              :maxlength="200"
              :requireTag="false"
              placeholder="Write a comment"
              v-model="leave.comments"
            />
          </div>

          <!-- Form actions -->
          <div class="flex justify-end gap-4 pt-2">
            <span class="relative group">
              <ButtonComponent
                @click="dissapproveEmployeeRequest.mutate"
                variant="with_border"
                data-cy="dissaprove"
                :disabled="
                  leave.comments.length < 1 ||
                  leave.status === 'APPROVED' ||
                  leave.status === 'DENIED'
                "
                :loading="dissapproveEmployeeRequest.loading.value"
              >
                Disapprove
              </ButtonComponent>
              <div
                data-cy="commentRequired"
                v-if="
                  leave.comments.length === 0 &&
                  leave.status !== 'APPROVED' &&
                  leave.status !== 'DENIED'
                "
                class="
                  absolute
                  z-10
                  hidden
                  p-2
                  overflow-hidden
                  text-sm
                  whitespace-normal
                  bg-opacity-90 bg-[#101840]
                  rounded
                  w-60
                  max-h-32
                  N0
                  -left-40
                  group-hover:block
                  -top-8
                "
              >
                A comment is required for disapproval.
              </div>
            </span>

            <ButtonComponent
              @click="approveEmployeeRequest.mutate"
              data-cy="approve"
              :disabled="
                leave.status === 'APPROVED' || leave.status === 'DENIED'
              "
              :loading="approveEmployeeRequest.loading.value"
            >
              Approve
            </ButtonComponent>
          </div>
        </div>
      </GDialog>

      <!-- Archive dialog -->
      <GDialog v-model="archiveFormDialog" max-width="29.688rem">
        <ArchiveDialog
          @close-dialog="archiveFormDialog = false"
          :component-title="archiveDialogTitle"
          :deleteBtn="archiveDialogDeleteBtn"
          @delete="archive"
          :delete-message="archiveDialogDeleteMessage"
        />
      </GDialog>
    </div>

    <!-- Dialogs for export CSV -->
    <LeaveHrCsvExportDialog
      v-model="hrCsvExportDialog"
      @closeDialog="hrCsvExportDialog = false"
      v-if="hrCsvExportDialog"
    />

    <AlertComponent :message-block="messageBlock" />
  </template>
  <div v-else-if="env.VUE_APP_HR_DASHBOARD">
    <VersionDisplay class="" />
  </div>
  <div class="flex flex-col" v-else>
    <div class="p-3 mx-auto mt-10"></div>
    <NotAuthorised class="" />
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, computed, watchEffect, onMounted } from "vue";
import { useStore } from "@/store";
import { useAccessStore } from "@/store/storeLeavesPermissions";
import { removeActionHeader } from "@/store/permissionFunctions";
import { LeaveHrApproval } from "@/types/hr-dashboard/leaveHrApprovals";
import {
  formatDateShort,
  createStatusRef,
  RequestStatus,
  updateHrRequestTotal,
  filterByHrApproved,
  formatLeaveCount,
  formatDate,
  getUserNameInitials,
} from "@/helpers/leaveRequestFunctions";
import {
  searchedQuery,
  messageBlock,
  setLocal,
  showOnboarding,
} from "@/helpers/book-leaves/leaveRequests";
import {
  leave,
  statusClass,
  statusText,
  isChatVisible,
  toggleChat,
  getFileName,
  downloadFile,
  diabaledDate,
} from "@/helpers/hr-dashboard/hrManager";
import CloseCircle from "@/assets/svg-components/close-circle.vue";
import DownloadIcon from "@/assets/svg-components/download-icon.vue";
import ConversationIcon from "@/assets/svg-components/conversation-icon.vue";
import EditIcon from "@/assets/edit-svg.vue";
import TrashIcon from "@/assets/trash-svg.vue";
import MultiLineInputBox from "@/common/ui-kit/Inputs/TextArea.vue";
import ButtonComponent from "@/common/ui-kit/button/ButtonComponent.vue";
import LoaderIconVue from "@/common/ui-kit/loader/LoaderIcon.vue";
import TableComponentVue from "@/common/ui-kit/table/TableComponent.vue";
import paginator from "@/common/ui-kit/pagination/PaginationComponent.vue";
import AlertComponent from "@/common/ui-kit/AlertComponent.vue";
import OnboardingCardComponent from "@/common/components/OnboardingCardComponent.vue";
import DatePicker from "vue-tailwind-datepicker";
import ProfileImageComponent from "@/common/components/ProfileImageComponent.vue";
import LeaveHrCsvExportDialog from "@/reuseable/hr-dashboard/HrCsvExport.vue";
import SearchComponent from "@/common/components/SearchComponent.vue";
import DropdownMenu from "@/common/components/DropdownMenu.vue";
import ArchiveDialog from "@/common/ui-kit/DeleteDialog.vue";
import NotAuthorised from "@/common/components/NotAuthorized.vue";
import VersionDisplay from "@/common/components/HelloWorld.vue";
import { useQuery, useMutation, useQueryLoading } from "@vue/apollo-composable";
import { APPROVE_HR_LEAVE_REQUEST } from "@/graphql/mutations/hr-dashboard/setHrApprovals";
import {
  GET_HR_REQUESTS,
  GET_TOTAL_HR_REQUESTS,
  GET_EMPLOYEE_INFO,
  GET_TOUCH_POINT_COMMENTS,
} from "../../graphql/queries/hr-dashboard/getHrApprovals";
import { GET_ACCRUED_DAYS } from "@/graphql/queries/book-leaves/getLeaveRequests";
import { SOFT_DELETE_LEAVE_REQUEST } from "@/graphql/mutations/book-leaves/setLeaveRequests";
import { sucessMessages, infoMessages, timer } from "@/constants";

// Constants
const env = process.env;
const store = useStore();

// Reactive Variables
const Access = useAccessStore();
const permissions = Access.permissions?.settings?.leave_management;
const approverPermissions = Access.permissions?.settings?.approvers;
const leavesPerm = permissions ?? {};
const approversPerm = approverPermissions ?? {};
const currentUserId = store?.user?.user_id;

const addDialog = ref(false);
const editMode = ref(false);
const ListHrRequests = ref<LeaveHrApproval[]>([]);
const hrCsvExportDialog = ref(false);
const archiveFormDialog = ref(false);
const queryLoading = useQueryLoading();

const headers = [
  { key: "employee_name", label: "Employee name" },
  { key: "duration", label: "Duration" },
  { key: "leave_type", label: "Leave type" },
  { key: "days_off", label: "Days" },
  { key: "status", label: "Status" },
  { key: "action", label: "Actions" },
];

// computed properties
const displayedHeaders = computed(() =>
  removeActionHeader(
    headers,
    leavesPerm.manage_hr_dashboard || approversPerm.manage_leave_approvers
  )
);

const emptyMessage = computed(() => {
  if (tableData.value.length === 0) {
    if (requestStatus.value === RequestStatus.APPROVED) {
      return "No approved leave requests";
    } else if (requestStatus.value === RequestStatus.PENDING) {
      return "No pending leave requests";
    } else if (requestStatus.value === RequestStatus.DENIED) {
      return "No disapproved leave requests";
    } else {
      return "No leave requests";
    }
  } else {
    return "";
  }
});

const tableData = computed<LeaveHrApproval[]>(() => {
  if (searchedQuery.value.trim() !== "") {
    return ListHrRequests?.value?.filter((item) => {
      const fullName = item.user?.full_name || "";
      return fullName.toLowerCase().includes(searchedQuery.value.toLowerCase());
    });
  } else {
    let filteredData = filterByHrApproved(
      ListHrRequests.value as LeaveHrApproval[],
      requestStatus.value
    );

    filteredData = filteredData.filter((item) => {
      return (
        item.user?.full_name !== null && item.user?.full_name !== undefined
      );
    });

    return filteredData.slice();
  }
});

const filteredComments = computed(() => {
  return leave.touch_point_comments?.filter(
    (comment) => comment?.message !== undefined && comment?.message !== ""
  );
});

// functions
const close = () => {
  leave.id = "";
  leave.user_id = "";
  leave.comments = "";
  editMode.value = false;
  addDialog.value = false;
};

watchEffect(() => {
  if (!addDialog.value) {
    close();
  }
});

const dropdownOptions = computed(() => [
  {
    id: 1,
    name: "View",
    label: "View Details",
    icon: EditIcon,
    fill: "#696F8C",
    allowAccess: true,
  },
  {
    id: 2,
    name: "Remove",
    label: "Remove",
    icon: TrashIcon,
    fill: "#696F8C",
    allowAccess: true,
  },
]);

const requestStatus = createStatusRef(RequestStatus.PENDING);
const handleStatusUpdate = (status: RequestStatus) => {
  requestStatus.value = status;
};

const requestTotal = reactive({
  [RequestStatus.PENDING]: 0,
  [RequestStatus.APPROVED]: 0,
  [RequestStatus.DENIED]: 0,
});

const variables = reactive({
  data: {
    take: 10,
    skip: 0,
    status: requestStatus.value,
    organization_id: store?.user?.organization,
    search: null as string | null,
  },
});

const variablesTotal = reactive({
  data: {
    organization_id: store?.user?.organization,
    status: requestStatus.value,
    search: null as string | null,
  },
});

watchEffect(() => {
  variables.data.status = requestStatus.value;
  variablesTotal.data.status = requestStatus.value;
  variables.data.skip = 0;
});

function searchTableData(searchString: string) {
  searchedQuery.value = searchString;
  variables.data.search =
    searchString.trim() !== "" ? searchString.trim() : null;
  variablesTotal.data.search =
    searchString.trim() !== "" ? searchString.trim() : null;
  leaveHrRequests.refetch(variables);
  leaveTotalHrRequests.refetch(variablesTotal);
}

// Mutations & Queries
const leaveHrRequests = useQuery(GET_HR_REQUESTS, variables, {
  fetchPolicy: "network-only",
});

leaveHrRequests.onResult((result) => {
  ListHrRequests.value = result.data?.listHrLeaveRequests || [];
  updateHrRequestTotal(ListHrRequests.value, requestTotal);
});

const leaveTotalHrRequests = useQuery(GET_TOTAL_HR_REQUESTS, variablesTotal, {
  fetchPolicy: "network-only",
});
const countRequests = ref<number>(0);

onMounted(() => {
  leaveTotalHrRequests.refetch();
});

leaveTotalHrRequests.onResult((result) => {
  countRequests.value = result.data?.countHrLeaveRequests?.count || 0;
});

onMounted(() => {
  leaveHrRequests.refetch();
});

const dateValue = ref({
  startDate: "",
  endDate: "",
});

const datePickerKey = ref(0);

const { result, refetch: refetchTouchPointComments } = useQuery(
  GET_TOUCH_POINT_COMMENTS,
  {
    requestId: leave.id,
  }
);

const handleSelectOption = (item: LeaveHrApproval, name: string) => {
  if (name === "View") {
    callEmployeeRequest(item);
  } else if (name === "Remove") {
    handleArchiveClick(item);
  }
};

const callEmployeeRequest = async (item: LeaveHrApproval) => {
  leave.id = item.id;
  leave.status = item.status;
  leave.user_id = item.user_id;
  leave.full_name = item.user?.full_name;
  leave.file = item.file;
  leave.manager_approved = item.manager_approved;
  leave.first_last_days = item.first_last_days;
  leave.leave_type = item.leave_type;
  leave.profile_image = item.user?.profile_image;

  await refetchTouchPointComments({ requestId: leave.id });

  if (result.value) {
    leave.touch_point_comments = result.value.listCommentsById || [];
  }

  editMode.value = true;
  addDialog.value = true;

  if (leave.user_id) {
    await GetAccruedDaysByUserId.refetch({
      userId: leave.user_id?.toString(),
    });

    await GetEmployeeInformationByUserId.refetch({
      userId: leave.user_id?.toString(),
    });
  }

  dateValue.value = {
    startDate: formatDate(new Date(leave.first_last_days[0])),
    endDate: formatDate(new Date(leave.first_last_days[1])),
  };

  datePickerKey.value += 1;
};

// Mutations & Queries
const GetAccruedDays = ref({
  leaveDaysAccrued: 0,
  leaveDaysUsed: 0,
  max_accrual: 0,
});

const GetAccruedDaysByUserId = useQuery(GET_ACCRUED_DAYS, {
  userId: leave.user_id?.toString(),
});

GetAccruedDaysByUserId.onResult((result) => {
  const accruedData = result.data?.getAccruedDaysByUserId;

  if (accruedData?.totalAccumulated !== null && accruedData?.activeAccrual) {
    GetAccruedDays.value.leaveDaysAccrued = accruedData.totalAccumulated;
    GetAccruedDays.value.leaveDaysUsed =
      accruedData.activeAccrual.leaveDaysUsed;
    GetAccruedDays.value.max_accrual = accruedData.activeAccrual.max_accrual;
  }
});

const GetEmployeeInformation = ref<{
  profile_image: string;
  full_name: string;
  country: string;
  email: string;
  department_name: string;
  position_name: string;
}>({
  profile_image: "",
  full_name: "",
  country: "",
  email: "",
  department_name: "",
  position_name: "",
});

const GetEmployeeInformationByUserId = useQuery(GET_EMPLOYEE_INFO, {
  userId: leave.user_id?.toString(),
});

const isLoading = ref(true);

GetEmployeeInformationByUserId.onResult((result) => {
  const employeeInfo = result.data?.getLeavesEmployeeActiveInfo;

  GetEmployeeInformation.value = {
    profile_image: employeeInfo?.users?.profile_image,
    full_name: employeeInfo?.users?.full_name,
    email: employeeInfo?.users?.email,
    department_name: employeeInfo?.organization?.name,
    position_name: employeeInfo?.position?.position_name,
    country: employeeInfo?.organization?.country,
  };

  isLoading.value = false;
});

const approveEmployeeRequest = useMutation(APPROVE_HR_LEAVE_REQUEST, () => ({
  variables: {
    data: {
      request_id: leave.id,
      approved_by: currentUserId?.toString(),
      comments: leave.comments,
      approval: "APPROVED",
    },
  },
}));

approveEmployeeRequest.onDone(() => {
  leaveHrRequests.refetch(variables);
  leaveTotalHrRequests.refetch(variablesTotal);
  messageBlock.open = true;
  addDialog.value = false;
  close();
  messageBlock.severity = "success";
  messageBlock.message = "Leave request approved successfully";
  setTimeout(() => {
    messageBlock.open = false;
  }, timer.timerThree);
});

approveEmployeeRequest.onError((error) => {
  messageBlock.open = true;
  addDialog.value = false;
  close();
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : infoMessages.contactSupport;
  setTimeout(() => {
    messageBlock.open = false;
  }, timer.timerFive);
});

const dissapproveEmployeeRequest = useMutation(
  APPROVE_HR_LEAVE_REQUEST,
  () => ({
    variables: {
      data: {
        request_id: leave.id,
        approved_by: currentUserId?.toString(),
        comments: leave.comments,
        approval: "DENIED",
      },
    },
  })
);

dissapproveEmployeeRequest.onDone(() => {
  leaveHrRequests.refetch(variables);
  leaveTotalHrRequests.refetch(variablesTotal);
  messageBlock.open = true;
  addDialog.value = false;
  close();
  messageBlock.severity = "success";
  messageBlock.message = "Leave request dissaproved successfully";
  setTimeout(() => {
    messageBlock.open = false;
  }, timer.timerThree);
});

dissapproveEmployeeRequest.onError((error) => {
  messageBlock.open = true;
  addDialog.value = false;
  close();
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : infoMessages.contactSupport;
  setTimeout(() => {
    messageBlock.open = false;
  }, timer.timerFive);
});

const archiveDialogTitle = ref("");
const archiveDialogDeleteBtn = ref("");
const archiveDialogDeleteMessage = ref("");

const handleArchiveClick = (item: LeaveHrApproval) => {
  leave.id = item.id;
  leave.leave_type = item.leave_type;
  leave.status = item.status;
  leave.first_last_days_start = item.first_last_days[0];
  leave.first_last_days_end = item.first_last_days[1];
  leave.full_name = item.user?.full_name;

  archiveFormDialog.value = true;
  archiveDialogTitle.value = "Confirmation";
  archiveDialogDeleteBtn.value = "Remove";
  archiveDialogDeleteMessage.value = `${infoMessages.doYouWantToRemove} ${
    leave.full_name
  }'s ${leave.leave_type} from ${formatDateShort(
    leave.first_last_days_start
  )} to ${formatDateShort(leave.first_last_days_end)}?
`;
};

const archive_leave_request = useMutation(SOFT_DELETE_LEAVE_REQUEST, () => ({
  variables: {
    deleteBookedLeaveId: leave.id,
    isArchived: true,
  },
}));

const archive = () => {
  archive_leave_request.mutate();
};

archive_leave_request.onDone(() => {
  leaveHrRequests.refetch(variables);
  leaveTotalHrRequests.refetch(variablesTotal);
  messageBlock.open = true;
  messageBlock.severity = "success";
  archiveFormDialog.value = false;
  messageBlock.message = sucessMessages.removedSucessfully;
  setTimeout(() => {
    messageBlock.open = false;
  }, timer.timerThree);
});

archive_leave_request.onError((error) => {
  messageBlock.open = true;
  archiveFormDialog.value = false;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : infoMessages.contactSupport;
  setTimeout(() => {
    messageBlock.open = false;
  }, timer.timerThree);
});

const currentPage = ref<number>(1);

const loadMore = (num: number) => {
  variables.data.skip = (num - 1) * variables.data.take;
  leaveHrRequests.refetch(variables);
};
</script>
