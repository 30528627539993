import gql from "graphql-tag";

export const CREATE_LEAVE_TYPE = gql`
  mutation CreateLeaveType($data: CreateLeaveTypeInput!) {
    createLeaveType(data: $data) {
      id
      title
      description
      organization_id
      is_archived
      is_annual_default
    }
  }
`;

export const UPDATE_LEAVE_TYPE = gql`
  mutation UpdateLeaveType($data: UpdateLeaveTypeInput!) {
    updateLeaveType(data: $data) {
      id
      title
      description
      organization_id
      is_archived
      is_annual_default
    }
  }
`;

export const TOGGLE_LEAVE_TYPE_STATUS = gql`
  mutation ArchiveLeaveType($archiveLeaveTypeId: ID!, $isArchived: Boolean!) {
    archiveLeaveType(id: $archiveLeaveTypeId, is_archived: $isArchived) {
      id
      title
      description
      organization_id
      is_archived
      is_annual_default
    }
  }
`;

export const REMOVE_LEAVE_TYPE_STATUS = gql`
  mutation DeleteLeaveType($deleteLeaveTypeId: ID!) {
    deleteLeaveType(id: $deleteLeaveTypeId) {
      id
      title
      description
      organization_id
      is_archived
      is_annual_default
    }
  }
`;
