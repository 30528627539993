<script setup>
import { storeToRefs } from "pinia";
import { useStore } from "@/store";
import TabletAndMobileView from "@/common/components/TabletAndMobileView.vue";

const store = useStore();
const { showAll } = storeToRefs(store);
</script>

<template>
  <div
    class="container mt-4"
    :class="{ 'max-[974px]:hidden': showAll, 'max-[1084px]:hidden': !showAll }"
    data-cy="leaveDashboard"
  >
    <router-view></router-view>
  </div>

  <div :class="showAll ? 'min-[974px]:hidden' : 'min-[1085px]:hidden'">
    <div>
      <TabletAndMobileView />
    </div>
  </div>
</template>
