import gql from "graphql-tag";

export const LIST_HOLIDAYS = gql`
  query ListHolidays {
    listHolidays {
      id
      title
      description
      start_day
      end_day
      is_archived
      country
    }
  }
`;
