import { ConfigTypes } from "@/types/configTypes";

const link = process.env;
const config: Partial<ConfigTypes> = {
  performanceLink: link.VUE_APP_PERFORMANCE_URL,
  resourceLink: link.VUE_APP_PROJECT_URL,
  employeeManagerLink: link.VUE_APP_EMPLOYEE_URL,
  contractManagerLink: link.VUE_APP_CONTRACT_URL,
  clockItLink: link.VUE_APP_CLOCK_IT_URL,
  payrollLink: link.VUE_APP_PAYROLL_URL,
  invoiceManagerLink: link.VUE_APP_INVOICE_URL,
  procurementManagerLink: link.VUE_APP_PROCUREMENT_URL,
  leaveManagerLink: link.VUE_APP_LEAVE_URL,
  loansManagerLink: link.VUE_APP_LOANS_URL,
  incidenceManagerLink: link.VUE_APP_HILFE_URL,
  meetingRoomBookingLink: link.VUE_APP_MRBS_URL,
  mainPageLink: link.VUE_APP_MAIN_PAGE_URL,
};

export default config;
