import { defineStore } from "pinia";
import { Permissions } from "@/types/leavesPermissionsType";

export const useAccessStore = defineStore({
  id: "leavesPermissions",
  /**
   * The state of the store is an object with a single property called `permissions`.
   * The `permissions` property is an object of type `Permissions` which is empty by default.
   */
  state: () => ({
    permissions: {} as Permissions,
  }),
  getters: {},
  actions: {
    setPermission(permissions: Permissions) {
      this.permissions = permissions;
    },
  },
  persist: true,
});
