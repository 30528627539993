import gql from "graphql-tag";

export const LIST_LEAVE_POLICIES = gql`
  query ListLeavePolicies {
    listLeavePolicies {
      id
      title
      description
      leave_type_id
      max_accrual
      carry_over
      carry_over_expiry
      can_negate
      is_archived
      position_id
      user_id
    }
  }
`;

export const ALL_POSITIONS = gql`
  query GetAllLeavesPositions {
    getAllLeavesPositions {
      id
      position_name
    }
  }
`;

export const GET_LIST_OF_EMPLOYEES = gql`
  query ListLeaveEmployees {
    listLeaveEmployees {
      id
      full_name
    }
  }
`;
