#VideoList.vue
<template>
  <div class="flex gap-6">
    <div
      class="
        bg-white
        border border-[#E6E8F0]
        rounded-lg
        shadow-md
        w-80
        overflow-y-scroll
        h-[60vh]
        custom-scrollbar
      "
    >
      <div class="flex flex-col p-4 cursor-pointer">
        <!-- video list -->
        <div v-for="(video, index) in videos" :key="index" class="mb-2">
          <div
            class="flex gap-2 p-4 rounded-lg"
            :style="{ backgroundColor: video.isActive ? '#F4F6FA' : '' }"
            @click="setActiveVideo(index)"
          >
            <div>
              <VideoSvg />
            </div>
            <div>
              <p class="text-[#667085] P200">{{ video.title }}</p>
              <p class="N600 P100">{{ video.duration }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Video -->
    <div class="flex-1 border border-gray-200 rounded-lg">
      <div v-if="activeVideo">
        <video
          controls
          :src="activeVideo.url"
          class="w-full h-[60vh] rounded-lg justify-center items-center mx-auto"
        >
          <track src="" kind="subtitles" label="English" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import VideoSvg from "@/assets/svg-components/video-svg.vue";
import { videos } from "@/constants";

const setActiveVideo = (index: number) => {
  videos.value.forEach((video, i) => {
    video.isActive = i === index;
  });
};

const activeVideo = computed(() => {
  return videos.value.find((video) => video.isActive);
});
</script>

<style scoped>
/* Style the scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #edeff5;
  border-radius: 0 0 7px 7px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #d8dae5;
}
</style>
