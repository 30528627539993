<template>
    <div class="close">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.75" y="0.75" width="22.5" height="22.5" rx="11.25" stroke="#101840" stroke-width="1.5" />
            <path
                d="M12.0688 14.3506C11.5198 14.3506 11.0646 13.8954 11.0646 13.3464V13.0652C11.0646 11.5119 12.2027 10.7487 12.6312 10.4541C13.1267 10.1193 13.2873 9.89168 13.2873 9.54353C13.2873 8.87402 12.7384 8.32496 12.0688 8.32496C11.3993 8.32496 10.8504 8.87402 10.8504 9.54353C10.8504 10.0925 10.3951 10.5478 9.84608 10.5478C9.29707 10.5478 8.8418 10.0925 8.8418 9.54353C8.8418 7.76261 10.2879 6.31641 12.0688 6.31641C13.8498 6.31641 15.2959 7.76261 15.2959 9.54353C15.2959 11.07 14.1711 11.8333 13.756 12.1145C13.2338 12.4626 13.0731 12.6903 13.0731 13.0652V13.3464C13.0731 13.9088 12.6178 14.3506 12.0688 14.3506Z"
                fill="#101840" />
            <path
                d="M12.0707 17.6843C11.5083 17.6843 11.0664 17.2291 11.0664 16.6801C11.0664 16.1311 11.5217 15.6758 12.0707 15.6758C12.6197 15.6758 13.075 16.1311 13.075 16.6801C13.075 17.2291 12.6331 17.6843 12.0707 17.6843Z"
                fill="#101840" />
        </svg>
    </div>
</template>

<style scoped>
.close {
    cursor: pointer;
}
</style>