<template>
    <div class="close"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#8F95B2"
                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M8.9999 15.3787H13.9199C15.6199 15.3787 16.9999 13.9988 16.9999 12.2988C16.9999 10.5988 15.6199 9.21875 13.9199 9.21875H7.1499"
                stroke="#8F95B2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round" />
            <path d="M8.57 10.7691L7 9.18914L8.57 7.61914" stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>

    </div>
</template>

<style scoped>
.close {
    cursor: pointer;
}
</style>