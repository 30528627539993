<template>
  <template
    v-if="!env.VUE_APP_LEAVES_HR_APPROVAL && approversPerm.view_leave_approvers"
  >
    <!-- ONBOARDING CARD -->
    <OnboardingCardComponent
      v-if="tableData.length === 0 && showOnboarding"
      class="z-10"
      @btncall="setLocal"
      v-show="showOnboarding"
      title="Welcome to HR Leave Approvers"
      content="On this page you can manage employees that approve leave requests. Get started now!."
      data-cy="onboardingCard"
    />
    <div>
      <div class="BG0 py-2 rounded-md">
        <div
          class="flex items-center justify-between"
          v-if="approversPerm.manage_leave_approvers"
        >
          <h3 class="py-2" data-cy="leaveApproversTitle">Leave Approvers</h3>
          <div class="flex gap-4 items-center justify-between pb-4 pt-2">
            <SearchComponent data-cy="search" @search="searchTableData" />
            <ButtonComponent
              buttonLabel="Set approver"
              variant="primary"
              @click="openLeaveApproverDialog"
              data-cy="setApproverButton"
              class="shadow-md"
            />
          </div>
        </div>

        <!-- Warning Notification -->
        <div
          v-show="closeWarning && tableData.length === 0"
          class="
            my-4
            flex
            border-2 border-solid border-[#E6E8F0]
            rounded-lg
            bg-[#FAFBFF]
            gap-x-[0.69rem]
            py-2
            p-[0.69rem]
            justify-between
            items-center
          "
          data-cy="warningNotification"
        >
          <div class="flex items-center gap-x-4">
            <div class="">
              <InfoClose />
            </div>
            <p class="text-base N700">
              Setup leave approvers for your organisation.
              <span class="text-[#DD5928]"
                >Learn more about setting up leave approvers.</span
              >
            </p>
          </div>
          <div>
            <CloseCircle @click="updateCloseWarning" />
          </div>
        </div>
        <!-- Warning Notification -->

        <!-- Leave Approvers section -->
        <TableComponentVue
          :overflowXAuto="false"
          :headers="displayedHeaders"
          :items="tableData"
          :itemsPerPage="9"
          emptyMessage="You haven't add any approvers for your organisation"
        >
          <template #organization="slotProps">
            <span class="flex items-center gap-2 py-3">
              {{ (slotProps.item as LeaveHrApprovers)?.organization?.name }}
            </span>
          </template>

          <template #approver="slotProps">
            <span
              v-if="
                slotProps.item.user &&
                slotProps.item.user &&
                slotProps.item.user &&
                slotProps.item.user.full_name
              "
              class="flex items-center gap-2"
            >
              {{ slotProps.item.user.full_name }}
              <span
                v-if="(slotProps.item as LeaveHrApprovers)['description']"
                class="relative group"
              >
                <ToolTipIcon />
                <div
                  class="
                    w-80
                    max-h-32
                    bg-opacity-90 bg-[#101840]
                    z-10
                    N0
                    text-sm
                    rounded
                    p-2
                    absolute
                    left-full
                    hidden
                    group-hover:block
                    overflow-hidden
                    whitespace-normal
                    -top-8
                  "
                >
                  {{ (slotProps.item as LeaveHrApprovers)["description"] }}
                </div>
              </span>
            </span>
          </template>

          <template #action="slotProps">
            <div class="flex justify-start">
              <DropdownMenu
                :isLastItem="
                  slotProps.displayedItems > 3
                    ? slotProps.displayedItems - 1 === slotProps.index ||
                      slotProps.displayedItems - 2 === slotProps.index ||
                      slotProps.displayedItems - 3 === slotProps.index
                    : slotProps.displayedItems - 1 === slotProps.index ||
                      slotProps.displayedItems - 2 === slotProps.index
                "
                :bottom-class="'-mt-[10rem] absolute z-50'"
                data-cy="actions"
                @selectOption="(name: string) => handleSelectOption(slotProps.item, name)"
                :optionsList="computedDropdownMenu(slotProps.item)"
              />
            </div>
          </template>
        </TableComponentVue>

        <GDialog v-model="leaveApproverDialog" max-width="30rem">
          <div class="p-4">
            <div class="flex items-center justify-between">
              <h3 class="H600 N900">Set Approver</h3>
              <CloseCircle @click="close" />
            </div>

            <!-- Employee Informations -->
            <div class="my-5">
              <SelectFieldValue
                id="organisation"
                data-cy="organisation"
                class="BG0"
                label="Select Organisation"
                placeholder="Select Organisation"
                :disabled="editMode"
                :requireTag="true"
                :options="organisations.map((type) => type.title)"
                v-model="selectedOrganisation"
              />
            </div>
            <div class="my-5">
              <template v-if="editMode">
                <SelectFieldValue
                  data-cy="employees"
                  id="employee"
                  placeholder="Change final approver(s)"
                  :options="leaveemployees.map((type) => type.name)"
                  v-model="selectedEmployee"
                  optionValue="id"
                  label="Change final approver"
                  optionText="name"
                  class="BG0"
                  :requireTag="true"
                />
              </template>

              <template v-else>
                <Multiselect
                  data-cy="employees"
                  :options="leaveemployees"
                  v-model="selectedEmployee"
                  :multiple="true"
                  :closeOnSelect="false"
                  :blockKeys="['Delete']"
                  :groupSelect="true"
                  :clearOnSelect="false"
                  :preserveSearch="true"
                  :hideSelected="true"
                  :taggable="true"
                  :param="'name'"
                  :trackBy="'id'"
                  :placeholder="'Select approver(s)'"
                  :requireTag="true"
                  label="Add final approver(s)"
                />
              </template>
            </div>
            <div class="">
              <MultiLineInputBox
                data-cy="description"
                label="Description"
                :showlength="true"
                :maxlength="200"
                :requireTag="false"
                placeholder="Description"
                v-model="leave.description"
              />
            </div>

            <!-- Dialog action -->
            <div class="flex flex-col justify-end gap-4 pt-2">
              <div class="flex items-center justify-end gap-2">
                <ButtonComponent
                  button-label="Cancel"
                  :variant="'secondary'"
                  @click.stop="cancelLeaveApprover()"
                  dataCy="cancel"
                ></ButtonComponent>
                <ButtonComponent
                  data-cy="openLeaveEmployeeDialog"
                  @click="
                    editMode
                      ? updateLeaveApprover.mutate()
                      : addLeaveApprover.mutate()
                  "
                  :loading="loading"
                  :disabled="
                    selectedOrganisation.trim().length < 1 ||
                    selectedEmployee.length < 1
                  "
                >
                  {{ editMode ? "Update" : "Save" }}
                </ButtonComponent>
              </div>
            </div>
          </div>
        </GDialog>

        <!-- Delete Leave Approver dialog -->
        <GDialog v-model="removeLeaveApprovalDialog" max-width="27.688rem">
          <ArchiveDialog
            @close-dialog="removeLeaveApprovalDialog = false"
            :component-title="removeLeaveApprovalDialogTitle"
            :deleteBtn="removeLeaveApprovaDialogDeleteBtn"
            @delete="archive"
            :delete-message="removeLeaveApprovalDialogDeleteMessage"
          />
        </GDialog>

        <!-- Loading state -->
        <div v-if="queryLoading && approvals.length === 0" data-cy="loaderIcon">
          <LoaderIconVue />
        </div>
      </div>
    </div>
    <AlertComponent :message-block="messageBlock" data-cy="alertComponent" />
  </template>
  <div v-else-if="env.VUE_APP_LEAVES_HR_APPROVAL">
    <VersionDisplay class="" />
  </div>
  <div class="flex justify-around" v-else>
    <div class="flex flex-col">
      <div class="mt-10 p-3 mx-auto"></div>
      <NotAuthorized class="" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, computed, watchEffect } from "vue";
import {
  searchedQuery,
  searchTableData,
  messageBlock,
  setLocal,
  showOnboarding,
} from "@/helpers/book-leaves/leaveRequests";
import {
  LeaveHrApprovers,
  LeaveWithoutUser,
} from "@/types/hr-dashboard/leaveHrApprovals";
import CloseCircle from "@/assets/svg-components/close-circle.vue";
import InfoClose from "@/assets/svg-components/info-close.vue";
import EditIcon from "@/assets/edit-svg.vue";
import TrashIcon from "@/assets/trash-svg.vue";
import ToolTipIcon from "@/assets/svg-components/tooltip-icon.vue";
import ArchiveDialog from "@/common/ui-kit/DeleteDialog.vue";
import Multiselect from "@/common/ui-kit/Inputs/MultiSelectInput.vue";
import MultiLineInputBox from "@/common/ui-kit/Inputs/TextArea.vue";
import ButtonComponent from "@/common/ui-kit/button/ButtonComponent.vue";
import LoaderIconVue from "@/common/ui-kit/loader/LoaderIcon.vue";
import TableComponentVue from "@/common/ui-kit/table/TableComponent.vue";
import AlertComponent from "@/common/ui-kit/AlertComponent.vue";
import VersionDisplay from "@/common/components/HelloWorld.vue";
import NotAuthorized from "@/common/components/NotAuthorized.vue";
import OnboardingCardComponent from "@/common/components/OnboardingCardComponent.vue";
import SearchComponent from "@/common/components/SearchComponent.vue";
import SelectFieldValue from "@/common/ui-kit/Inputs/SelectField.vue";
import DropdownMenu from "@/common/components/DropdownMenu.vue";
import {
  useQuery,
  useMutation,
  useMutationLoading,
  useQueryLoading,
} from "@vue/apollo-composable";
import { GET_ORGANISATIONS } from "../../graphql/queries/leave-configuration/getLeaveTypes";
import {
  GET_LIST_OF_EMPLOYEES,
  GET_LIST_OF_APPROVERS,
} from "../../graphql/queries/hr-dashboard/getHrApprovals";
import {
  APPROVE_HR_APPROVERS,
  EDIT_HR_APPROVERS,
  REMOVE_HR_APPROVERS,
} from "../../graphql/mutations/hr-dashboard/setHrApprovals";
import { useAccessStore } from "@/store/storeLeavesPermissions";
import { removeActionHeader } from "@/store/permissionFunctions";
import {
  sucessMessages,
  infoMessages,
  timer,
  errorMessages,
} from "@/constants";
// Constants
const env = process.env;

// Reactive Variables
const Access = useAccessStore();
const permissions = Access.permissions?.settings?.approvers;
const approversPerm = permissions ?? {};
const closeWarning = ref(true);
const updateCloseWarning = () => (closeWarning.value = false);
const leaveApproverDialog = ref(false);

const addDialog = ref(false);
const editMode = ref(false);
const selectedFilter = ref("Active");
const selectedOrganisation = ref("");
const selectedEmployee = ref();
const removeLeaveApprovalDialog = ref(false);
const removeLeaveApprovalDialogTitle = ref("");
const removeLeaveApprovalDialogDeleteMessage = ref("");
const removeLeaveApprovaDialogDeleteBtn = ref("");
const approvals = ref<LeaveHrApprovers[]>([]);
const loading = useMutationLoading();
const queryLoading = useQueryLoading();

const headers = [
  { key: "organization", label: "Organization" },
  { key: "approver", label: "Approver" },
  { key: "action", label: "Actions" },
];

const leave: LeaveWithoutUser = reactive<LeaveWithoutUser>({
  id: "",
  organization: { name: "" },
  employeeName: "",
  organization_id: "",
  user_id: "",
  description: "",
  is_archived: false,
});

// computed properties
const displayedHeaders = computed(() =>
  removeActionHeader(headers, approversPerm.manage_leave_approvers)
);

const { result: leaveEmployeeResult } = useQuery(GET_LIST_OF_EMPLOYEES);
const leaveemployees = computed<{ id: string; name: string }[]>(() => {
  if (leaveEmployeeResult.value?.listLeaveEmployees) {
    return leaveEmployeeResult.value.listLeaveEmployees
      .filter(
        (item: { id: string; full_name: string | null }) =>
          item.full_name !== "" && item.full_name !== null
      )
      .map((item: { id: string; full_name: string }) => ({
        id: item.id,
        name: item.full_name,
      }));
  } else {
    return [];
  }
});

const organisations = computed<{ id: string; title: string }[]>(() => {
  return (
    organizationsResult.value?.getAllLeavesOrganizations?.map(
      (item: { id: string; name: string }) => ({
        id: item?.id,
        title: item?.name || "",
      })
    ) || []
  );
});

const tableData = computed(() => {
  if (searchedQuery.value.trim() !== "") {
    return approvals?.value?.filter(
      (item: { organization: { name: string } }) => {
        return (
          item?.organization?.name
            .toLowerCase()
            .indexOf(searchedQuery.value.toLowerCase()) != -1
        );
      }
    );
  } else {
    const filteredData = (approvals.value as LeaveHrApprovers[]).filter(
      (item) => {
        if (selectedFilter.value === "Active") {
          return !item.is_archived;
        }
      }
    );

    return filteredData.slice();
  }
});

// functions
const openLeaveApproverDialog = () => {
  leaveApproverDialog.value = true;
};

const close = () => {
  selectedOrganisation.value = "";
  selectedEmployee.value = [];
  leave.description = "";
  leaveApproverDialog.value = false;
  editMode.value = false;
};

watchEffect(() => {
  if (!leaveApproverDialog.value) {
    close();
  }
});

const cancelLeaveApprover = () => {
  close();
};

const computedDropdownMenu = (item: LeaveHrApprovers) => {
  const options = [];

  options.push({
    id: 1,
    name: "Edit",
    label: "Edit",
    icon: EditIcon,
    fill: "#696F8C",
    allowAccess: true,
  });

  options.push({
    id: 2,
    name: "Remove",
    label: "Remove",
    icon: TrashIcon,
    fill: "#696F8C",
    allowAccess: true,
  });

  return options;
};

const handleSelectOption = (item: LeaveHrApprovers, name: string) => {
  if (name === "Edit") {
    editApprover(item);
  } else if (name === "Remove") {
    removeLeaveApprover(item);
  }
};

const editApprover = (item: LeaveHrApprovers) => {
  leave.id = item.id;
  selectedOrganisation.value =
    organisations.value.find(
      (leaveType) => leaveType.id === item.organization_id
    )?.title ?? "Unknown Organisation";

  const employeeName =
    leaveemployees.value.find((leaveType) => leaveType.id === item.user_id)
      ?.name ?? "Unknown Employee";

  selectedEmployee.value = employeeName;

  leave.description = item.description;
  editMode.value = true;
  leaveApproverDialog.value = true;
};

// Mutaions & Queries
const { result: organizationsResult } = useQuery(GET_ORGANISATIONS);
const leaveApprovers = useQuery(GET_LIST_OF_APPROVERS, selectedFilter);

leaveApprovers.onResult((result) => {
  if (result.data?.listLeaveApprovers) {
    approvals.value = result.data.listLeaveApprovers;
  }
});

type User = { id: string };
const addLeaveApprover = useMutation(APPROVE_HR_APPROVERS, () => {
  let userIds: string[] = [];

  const selectedEmployeesArray = Array.isArray(selectedEmployee.value)
    ? selectedEmployee.value
    : [selectedEmployee.value];

  userIds = selectedEmployeesArray.flatMap((employee): string[] => {
    if (typeof employee === "object" && employee !== null && "id" in employee) {
      const user = employee as User;
      return [user.id];
    } else if (typeof employee === "string") {
      return [employee];
    }
    return [];
  });

  return {
    variables: {
      data: {
        description: leave.description,
        organization_id:
          organisations.value.find(
            (org) => org.title === selectedOrganisation.value
          )?.id ?? "",
        user_ids: userIds,
      },
    },
  };
});

const { refetch: refetchLeaveApprovers } = useQuery(GET_LIST_OF_APPROVERS);
addLeaveApprover.onDone(() => {
  refetchLeaveApprovers();
  close();
  messageBlock.open = true;
  messageBlock.severity = "success";
  messageBlock.message = sucessMessages.leaveApproverAdded;
  setTimeout(() => {
    messageBlock.open = false;
  }, timer.timerThree);
});

addLeaveApprover.onError((error) => {
  messageBlock.open = true;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100
      ? error.message
      : errorMessages.leaveApproverCreationFailed;
  close();
  setTimeout(() => {
    messageBlock.open = false;
  }, timer.timerFive);
});

const removeLeaveApprover = (item: LeaveHrApprovers) => {
  leave.id = item.id;
  leave.employeeName = item.user?.full_name;
  leave.organization.name = item.organization?.name;
  leave.is_archived = item.is_archived;

  try {
    removeLeaveApprovalDialog.value = true;
    removeLeaveApprovalDialogTitle.value = "Confirmation";
    if (leave.employeeName) {
      removeLeaveApprovalDialogDeleteMessage.value = `Do you really want to remove ${leave.employeeName} as ${leave.organization?.name} approver?`;
    } else {
      removeLeaveApprovalDialogDeleteMessage.value = `<span class='P250 N800'>${leave.organization?.name} has no assigned approver.`;
    }
    removeLeaveApprovaDialogDeleteBtn.value = "Remove";
  } catch (error) {
    // error;
  }
};

const remove_leave_approvers = useMutation(REMOVE_HR_APPROVERS, () => ({
  variables: {
    approverId: leave.id,
  },
}));

const archive = () => {
  remove_leave_approvers.mutate();
};

remove_leave_approvers.onDone(() => {
  refetchLeaveApprovers();
  messageBlock.open = true;
  removeLeaveApprovalDialog.value = false;
  messageBlock.severity = "success";
  close();
  messageBlock.message = sucessMessages.leaveAproverRemoved;
  setTimeout(() => {
    messageBlock.open = false;
  }, timer.timerThree);
});

remove_leave_approvers.onError((error) => {
  messageBlock.open = true;
  removeLeaveApprovalDialog.value = false;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : infoMessages.contactSupport;
  close();
  setTimeout(() => {
    messageBlock.open = false;
  }, timer.timerFive);
});

const updateLeaveApprover = useMutation(EDIT_HR_APPROVERS, () => ({
  variables: {
    leaveTypeId: leave.id,
    data: {
      id: leave.id,
      organization_id: selectedOrganisation.value
        ? organisations.value.find(
            (type) => type.title === selectedOrganisation.value
          )?.id
        : null,
      user_id: selectedEmployee.value
        ? leaveemployees.value.find(
            (type) => type.name === selectedEmployee.value
          )?.id
        : null,
      description: leave.description,
    },
  },
}));

updateLeaveApprover.onDone(() => {
  refetchLeaveApprovers();
  messageBlock.open = true;
  messageBlock.severity = "success";
  close();
  messageBlock.message = sucessMessages.leaveApproverUpdatedSuccessfully;
  setTimeout(() => {
    messageBlock.open = false;
  }, timer.timerThree);
});

updateLeaveApprover.onError((error) => {
  messageBlock.open = true;
  addDialog.value = false;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : infoMessages.contactSupport;
  close();
  setTimeout(() => {
    messageBlock.open = false;
  }, timer.timerFive);
});
</script>
