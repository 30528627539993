<template>
  <div>
    <!-- div for dropdown svg -->
    <div class="relative">
      <div :data-cy="dataCy" @click="showdropDown = !showdropDown">
        <div v-if="showActionIcon">
          <Actions_Icon />
        </div>
        <div v-if="showDateIcon">
          <DateSvg />
        </div>
      </div>
      <!-- div for the dropdown -->
      <div class="absolute z-50 min-w-[10rem]" :class="[absoluteClass, isLastItem ? bottomClass : '']"
        v-if="showdropDown">
        <ul class="flex flex-col rounded-lg bg-[#ffffff] shadow-lg">
          <p class="P100 N600 p-4">ACTIONS</p>
          <li v-for="option of optionsList" :key="option.id"
            :data-cy="option.dataCy != undefined ? option.dataCy : option.name">
            <div v-if="option.allowAccess != undefined ? option.allowAccess : true" class="
                P200
                flex
                content-center
                text-[#474d66]
                px-4
                py-3
                hover:text-[#dd5928] hover:bg-[#f9e0d7] hover:border-l-[3px]
                border-[#dd5928]
                cursor-pointer
              " @mouseover="activeOption = option.id" @mouseleave="activeOption = 0" @click="
                [
                  $emit('selectOption', option.name),
                  (showdropDown = !showdropDown)
                ]
                ">
              <div class="flex flex-row gap-2">
                <div v-if="option.icon" class="flex items-center">
                  <component :is="option.icon" :fill="activeOption == option.id
                      ? '#dd5928'
                      : option.fill
                        ? option.fill
                        : '#696F8C'
                    " :key="option.id" />
                </div>
                <div v-if="option.label" :class="optionLabelStyle">
                  {{ option.label }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref, watch } from "vue";
import EditSvg from "@/assets/edit-svg.vue";
import ArchiveSvg from "@/assets/archive-svg.vue";
import Actions_Icon from "@/assets/actions-icon.vue";
import DateSvg from "@/assets/date-svg.vue";
import { OptionsType } from "@/types/componentTypes";

defineProps({
  optionsList: {
    type: Array as PropType<OptionsType[]>,
    default: () => [
      {
        id: 1,
        name: "edit",
        label: "Edit",
        icon: EditSvg,
        fill: "#696F8C",
        dataCy: "edit",
        allowAccess: true
      },
      {
        id: 2,
        name: "archive",
        label: "Archive",
        icon: ArchiveSvg,
        fill: "#696F8C",
        dataCy: "archive",
        allowAccess: true
      }
    ]
  },
  menuStyle: {
    type: String
  },
  optionLabelStyle: {
    type: String,
    default: "flex whitespace-nowrap"
  },
  absoluteClass: {
    type: String,
    default: "right-0"
  },
  dataCy: {
    type: String,
    default: "actions"
  },
  showDateIcon: {
    type: Boolean,
    default: false
  },
  showActionIcon: {
    type: Boolean,
    default: true
  },
  isLastItem: {
    type: Boolean,
    default: false
  },
  bottomClass: {
    type: String,
    default: ""
  }
});

const showdropDown = ref(false);
const activeOption = ref(0);

watch(showdropDown, () => {
  if (showdropDown.value) {
    const stopInterval = setInterval(() => {
      if (!activeOption.value) {
        showdropDown.value = false;
        clearInterval(stopInterval);
      }
    }, 2000);
  } else {
    activeOption.value = 0;
  }
});
</script>
