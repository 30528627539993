<template>
    <div class="close"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.7598 16.3703C10.3398 16.3703 9.94984 16.2703 9.59984 16.0703C8.79984 15.6103 8.33984 14.6703 8.33984 13.4803V10.5203C8.33984 9.34029 8.79984 8.39029 9.59984 7.93029C10.3998 7.47029 11.4398 7.54029 12.4698 8.14029L15.0398 9.62029C16.0598 10.2103 16.6498 11.0803 16.6498 12.0003C16.6498 12.9203 16.0598 13.7903 15.0398 14.3803L12.4698 15.8603C11.8898 16.2003 11.2998 16.3703 10.7598 16.3703ZM10.7698 9.13029C10.6098 9.13029 10.4698 9.16029 10.3598 9.23029C10.0398 9.42029 9.84984 9.89029 9.84984 10.5203V13.4803C9.84984 14.1103 10.0298 14.5803 10.3598 14.7703C10.6798 14.9603 11.1798 14.8803 11.7298 14.5603L14.2998 13.0803C14.8498 12.7603 15.1598 12.3703 15.1598 12.0003C15.1598 11.6303 14.8498 11.2303 14.2998 10.9203L11.7298 9.44029C11.3698 9.23029 11.0398 9.13029 10.7698 9.13029Z"
                fill="#8F95B2" />
            <path
                d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
                fill="#8F95B2" />
        </svg></div>
</template>

<style scoped>
.close {
    cursor: pointer;
}
</style>