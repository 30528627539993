<template>
  <div class="absolute right-0 top-12 BG0 w-fit pl-3 pr-12 py-2.5 shadow-md z-10 rounded N800 P200">
    <a class="flex gap-2 mb-4 cursor-pointer" @click.prevent="viewEmployee()">
      <img src="@/assets/profile.svg" class="self-center" alt="profile" />
      <p>My profile</p>
    </a>
    <a class="flex gap-2 cursor-pointer" @click.prevent="userLogout()">
      <img src="@/assets/logout.svg" alt="logout" />
      <p>Logout</p>
    </a>
  </div>
</template>
<script setup lang="ts">
import { useAccessStore } from "@/store/storeLeavesPermissions";
import { logout, getToken } from "@/services/auth";
import { useStore } from "@/store";
import { encodeUrlPath } from '@/helpers/utils';

const env = process.env;
const store = useStore();
const loginHint: string = store.usersHint;

const viewEmployee = () => {
  let encryptedId = encodeUrlPath(store?.user?.user_id?.toString());
  let hostedURL = env.VUE_APP_EMPLOYEE_URL;
  location.href = `${hostedURL}view-employee/${encryptedId}/personal`;
};

const Access = useAccessStore();
const userLogout = () => {
  Access.$reset();
  logout().then(() => {
    window.localStorage.clear();
    window.location.href = `${env.VUE_APP_SSO}/logout?login-hint=${store.loginHint}&account=${store?.user?.email}&redirect-url=${env.VUE_APP_BASE}login`;
  });
};
</script>
