<template>
  <GDialog v-model="employeeDialog" max-width="65rem" persistent>
    <div class="p-4">
      <div
        data-cy="leaveDetailsModelHeader"
        class="flex items-center justify-between"
      >
        <h3 data-cy="leaveDetailsModelTitle" class="H600 N900 dark:text-">
          Employee Leave Details
        </h3>
        <CloseCircle
          data-cy="closeLeaveDetailsModel"
          @click="$emit('closeDialog')"
        />
      </div>

      <!-- Employee Informations -->
      <div class="flex flex-col gap-5 mt-2 lg:flex-row lg:gap-5 lg:m-5">
        <div
          class="
            border border-gray-300
            rounded-lg
            p-5
            h-[auto]
            lg:w-[40rem]
            w-full
          "
        >
          <h3 class="H600 N900">Applicant details</h3>
          <div class="flex flex-col gap-5 mt-2 lg:flex-row lg:gap-5 lg:m-5">
            <div class="w-[15rem]">
              <ProfileImageComponent
                :initials="
                  GetEmployeeInformation?.full_name
                    ? getUserNameInitials(GetEmployeeInformation.full_name)
                    : ''
                "
                :imgSrc="GetEmployeeInformation?.profile_image ?? ''"
                widthSize="10rem"
                heightSize="10rem"
                text-size="text-4xl"
                class="rounded-[50%]"
              />
            </div>
            <div v-if="!isLoading">
              <h3 class="mb-2">{{ GetEmployeeInformation?.full_name }}</h3>
              <p class="font-normal N700">
                {{ GetEmployeeInformation?.department_name || "No department" }}
                |
                {{ GetEmployeeInformation?.position_name || "No position" }}
              </p>

              <p
                class="font-normal N700 lg:w-80 lg:truncate"
                :title="GetEmployeeInformation?.email"
              >
                {{ GetEmployeeInformation?.email || "No email" }}
              </p>
            </div>
            <div v-else>Hold on...</div>
          </div>
        </div>
        <div
          class="
            border border-gray-300
            rounded-lg
            p-5
            h-[auto]
            lg:w-[25rem]
            w-full
          "
        >
          <h3 class="H600 N900 mb-5">Leave Summary</h3>
          <p data-cy="totalLeaveDaysUsed" class="mt-2">
            Total entitlement:
            <span class="N700">{{
              userLeaveDaysEntitlement?.toFixed(2) || "0.00"
            }}</span>
          </p>
          <p data-cy="totalLeaveDaysUsed" class="mt-2">
            For annual leave:
            <span class="N700">{{
              GetAccruedDays?.max_accrual?.toFixed(2) || "0.00"
            }}</span>
          </p>
          <p data-cy="availableDays" class="mt-2">
            Accrued days:
            <span class="N700">{{
              userLeaveDaysAvailable?.toFixed(2) || "0.00"
            }}</span>
          </p>
          <p data-cy="availableDays" class="mt-2">
            Available days:
            <span class="N700">{{
              GetAccruedDays?.leaveDaysAccrued?.toFixed(2) || "0.00"
            }}</span>
          </p>
          <p data-cy="leaveRequests" class="mt-2">
            Total leave requests:
            <span class="N700">{{
              ListLeavesBookedTimeOffTableData.length +
              ListLeavesBookedTableData.length
            }}</span>
          </p>
        </div>
      </div>
      <hr class="my-5" />
      <!-- Calculator, Add and Book leave Actions -->
      <div class="flex items-center justify-end gap-5">
        <span title="Leave Calculator">
          <LeaveCalculator
            data-cy="leaveCalculator"
            @click="calculatorDialog = true"
            class="border border-gray-200 p-2 rounded-lg cursor-pointer"
          />
        </span>
        <ButtonComponent
          v-if="leavesPermissions.manage_hr_employee_info"
          data-cy="addLeave"
          @click="hrAddAccruedDialog = true"
          variant="secondary"
          class="
            flex
            items-center
            with_border
            border border-gray-200
            h-[3rem]
            cursor-pointer
            truncate
          "
        >
          Add Leave
        </ButtonComponent>
        <ButtonComponent
          v-if="leavesPermissions.manage_hr_employee_info"
          data-cy="bookLeave"
          class="flex items-center h-12"
          @submit="
            [(editMode = false), (scheduleMode = false), (addDialog = true)]
          "
        >
          Book Leave
        </ButtonComponent>
      </div>

      <!-- Employee table -->
      <div class="mt-5 mb-5 h-[20rem] overflow-y-auto">
        <h4 class="py-2 my-3 H600 N900">
          Leave Requests ({{
            ListLeavesBookedTimeOffTableData.length +
            ListLeavesBookedTableData.length
          }})
        </h4>
        <TableComponentVue
          :overflowXAuto="false"
          :headers="displayedHeaders"
          :itemsPerPage="9"
          :items="CombinedLeavesTableData"
          emptyMessage="No Upcoming timeoff & History requests"
        >
          <!-- Slot Templates -->
          <template #days="{ item }">
            <TooltipComponent
              :text="getSpanTitle(item.first_last_days)"
              :position="'right'"
            >
              <div class="flex items-center w-fit">
                {{ formatDateShort(item.first_last_days[0]) }} -
                {{ formatDateShort(item.first_last_days[1]) }}
              </div>
            </TooltipComponent>
          </template>

          <template #leave_type="slotProps">
            <span class="flex items-center gap-2">
              {{ slotProps.item.leave_type }}
              <span
                v-if="hasTouchPointComments(slotProps.item)"
                class="relative group"
              >
                <ToolTipIcon />
                <div
                  class="
                    w-80
                    max-h-32
                    bg-opacity-90 bg-[#101840]
                    z-10
                    N0
                    text-sm
                    rounded
                    p-2
                    absolute
                    left-full
                    hidden
                    group-hover:block
                    overflow-hidden
                    whitespace-normal
                    -top-8
                  "
                >
                  {{ slotProps.item.touch_point_comments?.[0]?.message }}
                </div>
              </span>
            </span>
          </template>

          <template #days_off="slotProps">
            <span class="flex items-center">
              {{ (slotProps.item as LeaveRequest).days_off.toFixed(2) }}
              <span
                v-if="(slotProps.item as LeaveRequest).carry_over_used > 0"
                title="Carry over"
                class="text-[0.7rem] O300"
              >
                &nbsp;+{{ (slotProps.item as LeaveRequest).carry_over_used.toFixed(2) }}
              </span>
            </span>
          </template>

          <template #status="slotProps">
            <div
              class="px-2 rounded-lg flex items-center gap-x-2 w-fit truncate"
              :class="statusClass(slotProps.item)"
            >
              <span class="text-[1.3rem]">•</span>
              {{ getStatusText(slotProps.item) }}
            </div>
          </template>

          <template #actions="slotProps">
            <!-- Table Action Buttons -->
            <div class="flex justify-start">
              <DropdownMenu
                :isLastItem="isLastItem(slotProps)"
                :bottom-class="'-mt-[10rem] absolute z-50'"
                data-cy="actions"
                @selectOption="(name: string) => handleSelectOption(slotProps.item, name)"
                :optionsList="computedDropdownMenu(slotProps.item)"
              />
            </div>
          </template>
        </TableComponentVue>
      </div>

      <!-- Dialog action -->
      <div class="flex flex-col gap-4 justify-end pt-2">
        <div class="flex items-center justify-end gap-5">
          <ButtonComponent
            data-cy="openLeaveEmployeeDialog"
            @click="
              () => {
                $emit('closeDialog');
              }
            "
          >
            Ok
          </ButtonComponent>
        </div>

        <AlertComponent :message-block="messageBlock" />
      </div>
    </div>

    <!-- Dialogs for Book Request -->
    <LeaveRequestDialog
      v-if="addDialog"
      v-model="addDialog"
      @closeDialog="addDialog = false"
      :maxAccrual="GetAccruedDays?.max_accrual"
      :leaveDaysUsed="GetAccruedDays?.leaveDaysUsed"
      :leaveDaysAccrued="GetAccruedDays?.leaveDaysAccrued"
      :leaveDaysCarryOver="GetAccruedDays?.carry_over_days"
      :leaveDaysCanNegate="GetAccruedDays?.can_negate"
      :listLeavesHolidays="ListLeavesHolidays"
      :editLeaveRequest="editMode"
      :editLeaveRequestId="leave.id"
      :editLeaveRequestDescription="leave.description"
      :editLeaveRequestDocument="leave.document"
      :editLeaveRequestLeaveType="leave.leave_type"
      :editLeaveRequestFirstLastDaysStart="leave.first_last_days_start"
      :editLeaveRequestFirstLastDaysEnd="leave.first_last_days_end"
      :editLeaveRequestManagerId="leave.manager_id"
      :employee-id="employeeId"
    />

    <!-- Dialogs for employee calculator -->
    <LeaveCalculatorDialog
      v-model="calculatorDialog"
      @closeDialog="calculatorDialog = false"
      :employee-id="employeeId"
      :leaveDaysAccrued="GetAccruedDays?.leaveDaysAccrued"
      v-if="calculatorDialog"
    />

    <!-- Dialogs for employee add accruels -->
    <LeaveHrAddAccruedDialog
      v-model="hrAddAccruedDialog"
      @closeDialog="hrAddAccruedDialog = false"
      :employee-id="employeeId"
      :leaveDaysAccrued="GetAccruedDays?.leaveDaysAccrued"
      :employee-information="GetEmployeeInformation"
      v-if="hrAddAccruedDialog"
    />

    <!-- Archive dialog -->
    <GDialog v-model="archiveFormDialog" max-width="29.688rem">
      <ArchiveDialog
        @close-dialog="archiveFormDialog = false"
        :component-title="archiveDialogTitle"
        :deleteBtn="archiveDialogDeleteBtn"
        @delete="archive"
        :delete-message="archiveDialogDeleteMessage"
      />
    </GDialog>
  </GDialog>
</template>

<script setup lang="ts">
import { ref, toRefs, computed } from "vue";
import { useStore } from "@/store";
import { LeaveHoliday } from "@/types/leave-configuration/leaveHolidays";
import {
  LeaveRequest,
  EditLeaveRequest,
} from "@/types/book-leaves/leaveRequests";
import {
  formatDateShort,
  getUserNameInitials,
} from "@/helpers/leaveRequestFunctions";
import {
  leave,
  getSpanTitle,
  hasTouchPointComments,
  statusClass,
  getStatusText,
  isLastItem,
  messageBlock,
} from "@/helpers/book-leaves/leaveRequests";
import CloseCircle from "@/assets/svg-components/close-circle.vue";
import LeaveCalculator from "@/assets/svg-components/leave-calculator.vue";
import EditIcon from "@/assets/edit-svg.vue";
import TrashIcon from "@/assets/trash-svg.vue";
import ToolTipIcon from "@/assets/svg-components/tooltip-icon.vue";
import LeaveRequestDialog from "@/reuseable/book-leaves/LeaveRequestDialog.vue";
import LeaveCalculatorDialog from "@/reuseable/book-leaves/LeaveCalculator.vue";
import LeaveHrAddAccruedDialog from "@/reuseable/hr-dashboard/HrAddAccrued.vue";
import ButtonComponent from "@/common/ui-kit/button/ButtonComponent.vue";
import TableComponentVue from "@/common/ui-kit/table/TableComponent.vue";
import TooltipComponent from "@/common/ui-kit/Tooltip/TooltipComponent.vue";
import AlertComponent from "@/common/ui-kit/AlertComponent.vue";
import ProfileImageComponent from "@/common/components/ProfileImageComponent.vue";
import ArchiveDialog from "@/common/ui-kit/DeleteDialog.vue";
import DropdownMenu from "@/common/components/DropdownMenu.vue";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { LIST_HOLIDAYS } from "@/graphql/queries/leave-configuration/getHolidays";
import {
  UPCOMING_LIST_LEAVE_REQUEST,
  LIST_LEAVE_REQUEST,
  GET_ACCRUED_DAYS,
} from "@/graphql/queries/book-leaves/getLeaveRequests";
import {
  GET_HR_EMPLOYEES_OVERVIEW,
  GET_EMPLOYEE_INFO,
} from "@/graphql/queries/hr-dashboard/getHrApprovals";
import { SOFT_DELETE_LEAVE_REQUEST } from "@/graphql/mutations/book-leaves/setLeaveRequests";
import { useAccessStore } from "@/store/storeLeavesPermissions";
import { removeActionHeader } from "@/store/permissionFunctions";
import { sucessMessages, infoMessages, timer } from "@/constants";
const props = defineProps({
  employeeId: String,
  userCountry: String,
  userLeaveDaysAvailable: Number,
  userLeaveDaysEntitlement: Number,
});
const { employeeId, userCountry } = toRefs(props);

// Reactive Variables
const store = useStore();
const Access = useAccessStore();
const permissions = Access.permissions?.settings?.leave_management;
const leavesPermissions = permissions ?? {};

const editMode = ref(false);
const scheduleMode = ref(false);
const addDialog = ref(false);
const calculatorDialog = ref(false);
const hrAddAccruedDialog = ref(false);
const archiveFormDialog = ref(false);
const employeeDialog = ref(false);
const ListLeavesHolidays = ref<LeaveHoliday[]>([]);

const headers = [
  { key: "days", label: "Days" },
  { key: "leave_type", label: "Leave type" },
  { key: "days_off", label: "Duration" },
  { key: "status", label: "Status" },
  { key: "actions", label: "Actions" },
];

// computed properties
const displayedHeaders = computed(() =>
  removeActionHeader(headers, leavesPermissions.manage_hr_employee_info)
);

// functions
const computedDropdownMenu = (item: LeaveRequest) => {
  const options = [];

  options.push({
    id: 1,
    name: "Edit",
    label: "Edit",
    icon: EditIcon,
    fill: "#696F8C",
    allowAccess: true,
  });

  options.push({
    id: 2,
    name: "Remove",
    label: "Remove",
    icon: TrashIcon,
    fill: "#696F8C",
    allowAccess: true,
  });

  return options;
};

const handleSelectOption = (item: EditLeaveRequest, name: string) => {
  if (name === "Edit") {
    editLeaveRequest(item);
  } else if (name === "Remove") {
    handleArchiveClick(item);
  }
};

const editLeaveRequest = (item: EditLeaveRequest) => {
  leave.id = item.id;
  leave.description = item.touch_point_comments?.[0]?.message;
  leave.document = item.file;
  leave.leave_type = item.leave_type;
  leave.first_last_days_start = item.first_last_days[0];
  leave.first_last_days_end = item.first_last_days[1];
  leave.manager_id = item.manager_id;
  editMode.value = true;
  addDialog.value = true;
  scheduleMode.value = false;
};

// Mutations & Queries
const GetAccruedDays = ref({
  leaveDaysAccrued: 0,
  leaveDaysUsed: 0,
  max_accrual: 0,
  carry_over_days: 0,
  can_negate: true,
});

const GetAccruedDaysByUserId = useQuery(GET_ACCRUED_DAYS, {
  userId: employeeId,
});

GetAccruedDaysByUserId.onResult((result) => {
  if (result.data?.getAccruedDaysByUserId) {
    const accruedData = result.data.getAccruedDaysByUserId;
    GetAccruedDays.value.leaveDaysAccrued = accruedData?.totalAccumulated;
    GetAccruedDays.value.leaveDaysUsed =
      accruedData?.activeAccrual?.leaveDaysUsed;
    GetAccruedDays.value.carry_over_days = accruedData.carry_over_days || 0;
    GetAccruedDays.value.can_negate = true;
    GetAccruedDays.value.max_accrual = accruedData?.activeAccrual?.max_accrual;
  }
});

const GetEmployeeInformation = ref<{
  profile_image: string;
  full_name: string;
  country: string;
  email: string;
  department_name: string;
  position_name: string;
}>({
  profile_image: "",
  full_name: "",
  country: "",
  email: "",
  department_name: "",
  position_name: "",
});

const GetEmployeeInformationByUserId = useQuery(GET_EMPLOYEE_INFO, {
  userId: employeeId,
});

const isLoading = ref(true);

GetEmployeeInformationByUserId.onResult((result) => {
  if (result.data?.getLeavesEmployeeActiveInfo) {
    const employeeInfo = result.data.getLeavesEmployeeActiveInfo;

    GetEmployeeInformation.value = {
      profile_image: employeeInfo?.users?.profile_image,
      full_name: employeeInfo?.users?.full_name,
      email: employeeInfo?.users?.email,
      department_name: employeeInfo?.organization?.name,
      position_name: employeeInfo?.position?.position_name,
      country: employeeInfo?.organization?.country,
    };

    isLoading.value = false;
  }
});

const leaveHolidays = useQuery(LIST_HOLIDAYS);
const employeeCountry = ref(userCountry?.value);
leaveHolidays.onResult((result) => {
  if (result.data?.listHolidays) {
    const currentYear = new Date().getFullYear();
    ListLeavesHolidays.value = result.data.listHolidays.filter(
      (holiday: { is_archived: boolean; country: string; start_day: Date }) =>
        !holiday.is_archived &&
        holiday.country === employeeCountry.value &&
        new Date(holiday.start_day).getFullYear() === currentYear
    );
  }
});

const CombinedLeavesTableData = computed(() => {
  const combinedData = [
    ...ListLeavesBookedTimeOffTableData.value,
    ...ListLeavesBookedTableData.value,
  ];
  return combinedData;
});

const ListLeavesBookedTimeOffTableData = ref<LeaveRequest[]>([]);
const leaveBookedTimeOff = useQuery(UPCOMING_LIST_LEAVE_REQUEST, {
  userId: employeeId,
});

leaveBookedTimeOff.onResult((result) => {
  if (result.data?.getUpcomingTimeOff) {
    ListLeavesBookedTimeOffTableData.value = result.data.getUpcomingTimeOff;
  }
});

const ListLeavesBookedTableData = ref<LeaveRequest[]>([]);
const leaveBookedHistory = useQuery(LIST_LEAVE_REQUEST, {
  userId: employeeId,
});

leaveBookedHistory.onResult((result) => {
  if (result.data?.getLeaveHistoryByUserId) {
    ListLeavesBookedTableData.value = result.data.getLeaveHistoryByUserId;
  }
});

const archiveDialogTitle = ref("");
const archiveDialogDeleteBtn = ref("");
const archiveDialogDeleteMessage = ref("");

const handleArchiveClick = (item: EditLeaveRequest) => {
  leave.id = item.id;
  leave.leave_type = item.leave_type;
  leave.status = item.status;
  leave.first_last_days_start = item.first_last_days[0];
  leave.first_last_days_end = item.first_last_days[1];

  archiveFormDialog.value = true;
  archiveDialogTitle.value = "Confirmation";
  archiveDialogDeleteBtn.value = infoMessages.remove;
  archiveDialogDeleteMessage.value = `${infoMessages.doYouWantToRemove} ${
    leave.leave_type
  } from ${formatDateShort(leave.first_last_days_start)} to ${formatDateShort(
    leave.first_last_days_end
  )}?`;
};

const archive_leave_request = useMutation(SOFT_DELETE_LEAVE_REQUEST, () => ({
  variables: {
    deleteBookedLeaveId: leave.id,
    isArchived: true,
  },
}));

const archive = () => {
  archive_leave_request.mutate();
};

const { refetch: refetchGetLeaveAccruedData } = useQuery(GET_ACCRUED_DAYS, {
  userId: employeeId,
});
const { refetch: refetchTableData } = useQuery(LIST_LEAVE_REQUEST, {
  userId: employeeId,
});
const { refetch: refetchListLeavesBookedTimeOff } = useQuery(
  UPCOMING_LIST_LEAVE_REQUEST,
  {
    userId: employeeId,
  }
);
const variablesOverView = {
  data: {
    organization_id: store?.user?.organization,
  },
};
const { refetch: refetchOverviewCounts } = useQuery(
  GET_HR_EMPLOYEES_OVERVIEW,
  variablesOverView
);

archive_leave_request.onDone(() => {
  refetchTableData();
  refetchListLeavesBookedTimeOff();
  refetchGetLeaveAccruedData();
  refetchOverviewCounts();
  messageBlock.open = true;
  messageBlock.severity = "success";
  archiveFormDialog.value = false;
  messageBlock.message = sucessMessages.removedSucessfully;
  refetchGetLeaveAccruedData();
  setTimeout(() => {
    messageBlock.open = false;
  }, timer.timerThree);
});

archive_leave_request.onError((error) => {
  messageBlock.open = true;
  archiveFormDialog.value = false;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : infoMessages.contactSupport;
  setTimeout(() => {
    messageBlock.open = false;
  }, timer.timerThree);
});
</script>
