import { UserType } from "@/types/authTypes";
import { defineStore } from "pinia";

export const useStore = defineStore({
  id: "login_data",

  /**
   * The state of the user and organization
   * @property {string} email - The email of the user
   * @property {number} id - The id of the user
   * @property {number} manager - The id of the manager
   * @property {number} organization - The id of the organization
   * @property {Array<number>} adviser - The ids of the adviser
   * @property {string} url - The url of the organization
   * @property {string} userName - The name of the user
   * @property {Array<object>} allUsers - All users in the organization
   * @property {UserType} user - The user object
   * @property {string} profilePicture - The profile picture of the user
   * @property {Array<object>} empInfoUsers - The users in the employee information
   * @property {object} permissions - The permissions of the user
   * @property {number|null} currentStep - The current step of the onboarding process
   * @property {number|null} totalStep - The total steps of the onboarding process
   * @property {boolean} onboardingPending - Whether the onboarding process is pending
   * @property {UserType} userInfo - The user information
   * @property {string|number} cdcAdvisor - The cdcAdvisor of the user
   * @property {number} loanuser - The user id of the loan user
   * @property {boolean} showAll - Whether to show all employees or not
   * @property {string} loginHint - The login hint of the user
   * @property {string} usersHint - The users hint of the user
   */
  state: () => ({
    email: "",
    id: 0,
    manager: 0,
    organization: 0,
    adviser: [] as Array<number>,
    url: "",
    userName: "",
    allUsers: [] as Array<object>,
    user: {} as UserType,
    profilePicture: "",
    empInfoUsers: [] as Array<object>,
    permissions: {},
    currentStep: null,
    totalStep: null,
    onboardingPending: false as boolean,
    userInfo: {} as UserType,
    cdcAdvisor: "" as string | number,
    loanuser: 0,
    showAll: false,
    loginHint: "",
    usersHint: "",
  }),
  getters: {},
  actions: {
    setLoanUser(data: number) {
      this.loanuser = data;
    },
    setEmail(email: string) {
      this.email = email;
    },
    setId(id: number) {
      this.id = id;
    },
    setOrganization(id: number) {
      this.organization = id;
    },
    setManager(id: number) {
      this.manager = id;
    },
    setAdviser(ids: Array<number>) {
      this.adviser = ids;
    },
    setURL(url: string) {
      this.url = url;
    },
    setName(name: string) {
      this.userName = name;
    },
    setAllUsers(data: Array<object>) {
      this.allUsers = data;
    },
    setUser(data: UserType) {
      this.user = data;
    },
    setProfilePicture(url: string) {
      this.profilePicture = url;
    },
    setEmpInfoUsers(data: Array<object>) {
      this.empInfoUsers = data;
    },
    setPermission(data: object) {
      this.permissions = data;
    },
    setOnboardingStatus(data: boolean) {
      this.onboardingPending = data;
    },
    setUserInfo(data: UserType) {
      this.userInfo = data;
    },
    setCdcAdvisor(data: string | number) {
      this.cdcAdvisor = data;
    },
    setLoginHint(data: string) {
      this.loginHint = data;
    },
    setUsersHint(data: string) {
      this.usersHint = data;
    },
  },
  persist: true,
});

export const userInfo = defineStore({
  id: "user_info",
  state: () => ({
    userInfo: {} as object,
    empInfo: [] as Array<object>,
    empStatus: [] as Array<object>,
  }),
  getters: {},
  actions: {
    setUser(data: object) {
      this.userInfo = data;
    },

    setEmpInfo(data: Array<object>) {
      this.empInfo = data;
    },

    setEmpStatus(data: Array<object>) {
      this.empStatus = data;
    },
  },
  persist: false,
});
