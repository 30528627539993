<template>
  <div class="flex gap-20 items-center relative">
    <div class="flex flex-col w-[50vw]">
      <div
        class="w-full p-2 border rounded-lg cursor-pointer mb-4"
        v-for="(faqItem, index) in faqs"
        :key="index"
      >
        <div
          class="flex items-center justify-between"
          :class="faqItem.showItems ? 'mb-4' : ''"
          @click="toggleItems(index)"
        >
          <div class="flex gap-2 items-center">
            <p class="text-base N800">{{ faqItem.header }}</p>
          </div>
          <ToggleArrow :is-open="faqItem.showItems" />
        </div>

        <slot v-if="faqItem.showItems">
          <p class="text-base N600">{{ faqItem.response }}</p>
        </slot>
      </div>
    </div>
    <div>
      <HelpImageSvg />
    </div>
  </div>
</template>

<script setup lang="ts">
import ToggleArrow from "@/assets/svg-components/toggle-arrow.vue";
import HelpImageSvg from "@/assets/svg-components/help-image-svg.vue";
import { faqs } from "@/constants";
const toggleItems = (index: number) => {
  faqs.value[index].showItems = !faqs.value[index].showItems;
};
</script>
