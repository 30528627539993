<template>
  <div :id="id" :data-cy="dataCy" ref="containerRef">
    <div class="label items-center flex justify-between">
      <label :for="id" class=" whitespace-nowrap pt-0.5 mr-2 P250 N700 text-gray-600" v-if="showLabel">{{
        label
        }}</label>
      <span v-if="requireTag" class="
      bg-red-100 requiredSpan rounded-lg O400 text-xs px-1.5  mb-2 h-fit py-1 dark:bg-red-200 dark:text-red-900
        ">
        Required
      </span>
    </div>
    <div class="relative rounded-lg focus:outline-[#E47A53] focus:ring-[#F2BEAB] focus:ring
" :data-cy="dataCy" @keydown.tab="handleTabPress">
      <div class="whitespace-nowrap" :class="{ open: isOpen }" @click="toggleDropdown()">
        <div class="
          flex
          justify-between
          items-center
          gap-2
          w-full
          bg-gray-50
          border cursor-pointer
          border-[#d8dae5]
          hover:border-[#8F95B2]
          text-gray-900
          rounded-lg
          focus:outline-[#E47A53] focus:ring-[#F2BEAB] focus:ring
          dark:bg-gray-700
          dark:border-gray-600
          dark:placeholder-gray-400
          dark:text-white
          dark:focus:ring-[#F2BEAB]
          dark:focus:border[#d8dae5]
          p-2
          relative
          " :class="{ classes, 'outline-[#E47A53] ring ring-[#F2BEAB]': isOpen }" ref="toggleButtonRef">
          <div data-cy="selectedOption" class="truncate">
            <span class="" :class="selected ? '' : 'N600'">{{ selected || placeholder }}</span>
          </div>
          <div class="
                pointer-events-none
                text-gray-700 text-base
                duration-300
              ">
          </div>

          <div class="mr-1">

            <ToggleUpDownArrow />
          </div>
        </div>

        <div class="
            bg-gray-50
            border border-solid border-gray-300
            rounded-lg
            z-10
            shadow-md
            " :class="[isAbsolute ? 'absolute' : '', minWidth ? 'min-w-[100%]' : '']" v-show="isOpen" @click.stop
          data-cy="dropDownContainer">
          <div class="divide-y">
            <div v-if="showSearch" class="my-2 mx-2">
              <InputField showSearch :showlength="false" placeholder="Search" v-model="searchTerm"
                @input="filterOptions" @click.stop />
              <p class="text-base N600 pt-2" v-if="!filteredOptions?.length">{{ emptyMsg }}</p>

            </div>
          </div>
          <div class="flex items-center" :class="showClear ? 'justify-between' : ''">
            <p class="text-base N600 pt-2 mx-3" v-if="filteredOptions?.length">{{ placeholder }}</p>
            <ButtonComponent button-label="Clear" variant="secondary" v-if="showClear" @click="handleClear()" />
          </div>
          <div class="max-h-[192px] overflow-y-scroll pb-5" data-cy="dropDownList">
            <div v-if="isObject" v-for="(option, index) in filteredOptions" :key="option + index"
              @click.stop="toggleOption(option)" class="
              flex
                items-center
                cursor-pointer
                p-2
                mt-2
                w-full
                border-l-4
                border-gray-50
                hover:border-[#DD5928] hover:border-l-4 hover:bg-[#F9E0D7] text-[#474D66] hover:text-[#DD5928]
              "
              :class="{ 'border-[#ee5923] bg-[#F9E0D7] text-[#DD5928] border-l-4': option?.[param] === selected || option === selected }">
              <p class="whitespace-nowrap">{{ isObject ? option?.[param] : option }}</p>
            </div>
            <div v-else v-for="(option, index) in filteredOptions" :key="option" @click.stop="toggleOption(option)"
              class="
                flex
                items-center
                cursor-pointer
                p-2
                mt-2
                w-full
                border-l-4
                border-gray-50
                hover:border-[#DD5928] hover:border-l-4 hover:bg-[#F9E0D7] text-[#474D66] hover:text-[#DD5928]
              " :class="{ 'border-[#e95a27] bg-[#F9E0D7] text-[#DD5928] border-l-4': (selected === option) }">
              <p class="whitespace-nowrap">{{ option }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import InputField from "@/common/ui-kit/Inputs/InputField.vue";
import { ref, computed, PropType, onMounted, onUnmounted, watch } from "vue";
import ToggleUpDownArrow from "@/assets/toggle-up-down-arrow.vue";
import ButtonComponent from "../button/ButtonComponent.vue";

const props = defineProps({
  id: {
    type: String,
    required: true 
  },
  label: {
    type: String,
    default: "Label"
  },
  options: {
    type: Array as PropType<any[]>,
    required: true
  },

  placeholder: {
    type: String,
    default: "Select"
  },
  searchPlaceholder: {
    type: String,
    default: ""
  },
  modelValue: {
    type: [Object, String],
    default: null
  },


  isRequired: {
    type: Boolean,
    default: false
  },

  showSearch: {
    type: Boolean,
    default: true
  },

  disabled: {
    type: Boolean,
    default: false
  },
  param: {
    type: String,
    default: "name"
  },

  requireTag: {
    type: Boolean,
    default: false
  },
  showLabel: {
    type: Boolean,
    default: true
  },
  classes: {
    type: String,
    default: ""
  },
  minWidth: {
    type: String,
    default: "m-w-[100%]"
  },
  isObject: {
    type: Boolean,
    default: false
  },
  trackBy: {
    type: String,
    required: false
  },
  emptyMsg: {
    type: String,
    default: "No items found"
  },
  dataCy: {
    type: String,
    required: true
  },

  showClear: {
    type: Boolean,
    default: false
  },
  isAbsolute:
  {
    type: Boolean,
    default: true
  }
});

const searchTerm = ref("");
const isOpen = ref(false);
const emit = defineEmits(["update:modelValue", "onChange", "handleClear"]);


const filteredOptions = computed(() => {
  if (searchTerm) {
    if (props.isObject) {
      const term = searchTerm.value?.toLowerCase();
      return props.options?.filter((option: any) =>
        option?.[props.param]?.toLowerCase()?.includes(term)
      );
    } else {
      return props.options?.filter(option =>
        (option && typeof option === 'string') ?
          option.toLowerCase().includes(searchTerm.value?.toLowerCase())
          : false
      );
    }
  }
  return props.options;
});



function toggleDropdown() {
  isOpen.value = !isOpen.value;
}

const selected = ref((props.isObject ? props.modelValue?.[props.param] : props.modelValue))
function toggleOption(option: any) {
  const value = props.options?.find(option => Number(option?.[props.trackBy as string]) === Number(props.modelValue));
  const basicCondition = props.isObject && props.trackBy;
  if (props.isObject) {
    selected.value = option?.[props.param];
  } else if (basicCondition) {
    selected.value = value[props.param];
  }
  else {
    selected.value = option;
  }

  if (props.trackBy) {
    emit("update:modelValue", option[props.trackBy])
  }
  else {
    emit("update:modelValue", option)
  }

  isOpen.value = false;

}

function filterOptions() {
  isOpen.value = true;
}

const containerRef = ref<HTMLElement | null>(null);

const closeDropDown = (event: Event) => {
  if (!containerRef.value?.contains(event.target as HTMLElement | null)) {
    isOpen.value = false;
  }
};

onUnmounted(() => {
  document.removeEventListener("click", (event) => {
    closeDropDown(event);
  });
});

onMounted(() => {
  document.addEventListener("click", (event) => {
    closeDropDown(event);
  });
  const value = props.options?.find(option => Number(option?.[props.trackBy as string]) === Number(props.modelValue));

  const basicCondition = props.isObject && props.trackBy;
  if (props.isObject) {
    selected.value = props.modelValue?.[props.param];
  } else if (basicCondition) {
    selected.value = value[props.param];
  }
  else {
    selected.value = value;
  }
  if (props.trackBy) {
    selected.value = value?.[props.param]
    emit("update:modelValue", value?.[props.trackBy])
  }
  else {
    selected.value = props.modelValue
    emit("update:modelValue", props.modelValue)
  }
})


watch(props, (val) => {
  if (val.modelValue && val.options) {
    const value = val.options.find(option => Number(option?.[val.trackBy as string]) === Number(val.modelValue));

    const basicCondition = value && val.trackBy;
    if (val.isObject) {
      selected.value = val.modelValue?.[val.param];
    } else if (basicCondition) {
      selected.value = value?.[val.param];
    }
    else {
      selected.value = value;
    }

    if (val.trackBy && value) {
      selected.value = value?.[val.param];
      emit("update:modelValue", value[val.trackBy]);
    } else {
      selected.value = val.modelValue;
      emit("update:modelValue", val.modelValue);
    }
  }
});

const handleClear = () => {
  emit("handleClear")
  selected.value = props.placeholder;
}


const toggleButtonRef = ref<HTMLElement | null>(null);

const handleTabPress = () => {
  if (toggleButtonRef.value) {
    toggleButtonRef.value.focus();
  }
};
</script>