<template>
  <div
    class="container"
    :class="{ 'max-[974px]:hidden': showAll, 'max-[1084px]:hidden': !showAll }"
  >
    <BreadCrumbsComponent :breadcrumbs="breadcrumbs" class="p-2" />
    <div class="BG0 px-8 py-4 pb-8 rounded-md">
      <!-- header -->
      <div class="flex gap-10 items-center justify-between w-full">
        <div class="w-3/5 flex flex-col gap-8">
          <p class="N900 H600">Help</p>
        </div>
        <div
          class="w-3/5 flex flex-row gap-2 justify-end cursor-pointer"
          @click="handleBack"
        >
          <BackSvg />
          <p class="N600 P200">Back</p>
        </div>
      </div>

      <!-- FAQ's -->
      <div>
        <p class="my-2 N900 H500">Frequently asked questions</p>
        <AccordionComponent />
      </div>

      <!-- Videos -->
      <div class="mt-6">
        <p class="N900 H600">Check these videos out</p>
        <div class="mt-4">
          <VideoList />
        </div>
      </div>
    </div>
  </div>
  <div :class="showAll ? 'min-[974px]:hidden' : 'min-[1085px]:hidden'">
    <div>
      <TabletAndMobileView />
    </div>
  </div>
</template>

<script setup lang="ts">
import BreadCrumbsComponent from "@/common/ui-kit/bread-crumbs/BreadCrumbsComponent.vue";
import BackSvg from "@/assets/svg-components/back-icon-svg.vue";
import { useRouter } from "vue-router";
import AccordionComponent from "@/views/help-page/AccordionComponent.vue";
import VideoList from "@/views/help-page/VideoList.vue";
import { storeToRefs } from "pinia";
import { useStore } from "@/store";
import TabletAndMobileView from "@/common/components/TabletAndMobileView.vue";

const store = useStore();
const { showAll } = storeToRefs(store);
const route = useRouter();
const breadcrumbs = [
  {
    label: "Home",
    to: "/home",
  },
  { label: "Help", to: "/leave-assistance" },
];

const handleBack = () => {
  route.push("/home");
};
</script>
