import { ref } from "vue";
import { FaqItem, Video } from "@/types/configTypes";

export const errorMessages = {
  errorOcurred: "An error occurred.",
  unknownErorr: "An unknown error occurred.",
  fileAttachmentFailed: "File attachment failed",
  networkError: "Sorry, there was a network error!",
  leaveRequestFailed: "Leave request failed",
  leaveHolidayImportedFailed: "Leave holiday import failed",
  acessLevelCreationFailed: "Access level creation failed",
  failToUnassignedEmployee: "Failed to unassign employee",
  leaveHolidayCreationFailed: "Leave holiday creation failed",
  leavePolicyFailed: "Leave policy creation failed",
  leaveTypeFailed: "Leave type creation failed",
  errorFetchingPolicuies: "Error fetching assigned policies",
  leaveApproverCreationFailed: "Leave Approver creation failed",
};

export const infoMessages = {
  noApproverFound: "No approved leave request",
  contactSupport: "Please contact support",
  noUrlFound: "No URL found in upload",
  contactSupportOrtryAgain: "Please try again or contact support",
  noPendingRequest: "No pending leave requests",
  noDisApprovedRequest: "No disapproved leave requests",
  noLeaveRequest: "No leave requests",
  doYouWantToAchive: "Do you really want to archive",
  unAchiveAcessLevel: "Unarchive Access level",
  achiveAcessLevel: "Archive Access level",
  archive: "Archive",
  unArchive: "Unarchive",
  areYousureYouwantToUnarchive: "Are you sure you want to unarchive",
  removeLeaveHoliday: "Remove Leave Holiday",
  unarchiveHoliday: "Unarchive Leave Holiday",
  archiveLeaveHoliday: "Archive Leave Holiday",
  removeLeavePolicy: "Remove Leave Policy",
  remove: "Remove",
  removeAcessLevel: "Remove Access Level",
  archiveLeavePolicy: "Archive Leave Policy",
  unarchiveLeavePolicy: "Unarchive Leave Policy",
  areYousureYouwantToUnarchivePolicy: "Are you sure you want to unarchive",
  removeLeaveType: "Remove Leave Type",
  archiveLeaveType: "Archive Leave Type",
  unarchiveLeaveType: "Unarchive Leave Type",
  areYousureYouwantToUnarchiveType: "Do you really want to archive",
  leaveTypeAssigned: "This Leave Type has been assigned to",
  unassignType: "and archiving will permanently unassign",
  doYouWantToRemove: "Do you really want to remove",
};

export const sucessMessages = {
  fileAttached: "File attached successfully",
  leaveBooked: "Leave booked successfully",
  levaeUpdated: "Leave request updated successfully",
  accrualAdded: "Accrual added successfully",
  accrualSubtracted: "Accrual subtracted successfully",
  removedSucessfully: "Leave request removed successfully",
  leaveRequestApproved: "Leave request approved successfully",
  leaveRequestDisApproved: "Leave request dissaproved successfully",
  leaveHolidayImported: "Leave holiday imported successfully",
  leaveApproverAdded: "Leave Approver added successfully",
  leaveHolidayArchived: "Leave holiday archived successfully",
  leaveApproverUpdatedSuccessfully: "Leave approver updated successfully",
  acessLevelAdded: "Access level added successfully",
  acessLevelUpdatedSuccessfully: "Access level updated successfully",
  acessLevelRemoved: "Access level removed successfully",
  employeeUnassigned: "Employee unassigned successfully",
  achiveSucessfully: "Access level archived successfully",
  acessLevelArchiveSucessfully: "Access level unarchived successfully",
  leaveHolidayAdded: "Leave holiday added successfully",
  leaveHolidayUpdated: "Leave holiday updated successfully",
  leaveHolidayArchiveSucessfully: "Leave holiday archived successfully",
  leaveHolidayRemoved: "Leave holiday removed successfully",
  leaveHolidayUnarchiveSucessfully: "Leave holiday unarchived successfully",
  leavePolicyAdded: "Leave policy added successfully",
  leavePolicyUpdated: "Leave policy updated successfully",
  leavePolicyUnarchived: "Leave policy unarchived successfully",
  leavePolicyArchived: "Leave policy archived successfully",
  leaveTypeAdded: "Leave type added successfully",
  leaveTypeUpdated: "Leave type updated successfully",
  leaveTypeRemoved: "Leave type removed successfully",
  leaveTypeArchiveSucessfully: "Leave type archived successfully",
  leaveTypeUnarchiveSucessfully: "Leave type unarchived successfully",
  leaveAproverRemoved: "Leave approver removed successfully",
  leavePolictRemoved: "Leave policy removed successfully",
  leaveTypeArchived: "Leave type archived successfully",
};

export const timer = {
  timerFive: 5000,
  timerOneFive: 1500,
  timerThree: 3000,
};

export const faqs = ref<FaqItem[]>([
  {
    header:
      "How does the Leave Management Service work, and why is it necessary for me?",
    response:
      "All employees can book leaves and view their past bookings with the help of the Leave Management Service. Furthermore, it gives managers the ability to approve or deny leave requests. HR can also configure various settings like holidays, leave types, policies, and access levels.",
    showItems: true,
  },
  {
    header: "How are carryover leaves used?",
    response:
      "The dashboard will display any carryover leaves that are applied on top of the annual leave balance. After March 31st of the year, your carryover expires.",
    showItems: false,
  },
  {
    header: "Why can't I book different types of leaves?",
    response:
      "Employees are only able to book annual leave. For additional leave types, please contact human resources (HR).",
    showItems: false,
  },
]);

export const videos = ref<Video[]>([
  {
    title: "How to book a leave",
    duration: "0:37",
    url: "https://arms-development.s3.eu-west-1.amazonaws.com/assets/onboarding-media/book-a-leave.mp4",
    isActive: true,
  },
  {
    title: "How to approve and disapprove leave requests",
    duration: "0:36",
    url: "https://arms-development.s3.eu-west-1.amazonaws.com/assets/onboarding-media/approve-and-dissaprove-leave-requests.mp4",
    isActive: false,
  },
  {
    title: "HR employee information dashboard",
    duration: "0:44",
    url: "https://arms-development.s3.eu-west-1.amazonaws.com/assets/onboarding-media/hr-employee-leave-management.mp4",
    isActive: false,
  },
  {
    title: "How to configure leave configurations",
    duration: "2:17",
    url: "https://arms-development.s3.eu-west-1.amazonaws.com/assets/onboarding-media/leave-configurations.mp4",
    isActive: false,
  },
  {
    title: "Conclusion",
    duration: "0:10",
    url: "https://arms-development.s3.eu-west-1.amazonaws.com/assets/onboarding-media/leave-management-help-video-conclusion.mp4",
    isActive: false,
  },
]);

export const headers = [
  { key: "employee_name", label: "Employee name" },
  { key: "total_entitlement", label: "Total entitlement" },
  { key: "accrued", label: "Accrued days" },
  { key: "available", label: "Available days" },
  { key: "added", label: "Added" },
  { key: "deducted", label: "Deducted" },
  { key: "carry_over", label: "Carry over" },
  { key: "annual_used", label: "Annual leave taken" },
  { key: "sick_used", label: "Sick leave taken" },
  { key: "maternity_used", label: "Maternity leave taken" },
  { key: "bereavement_used", label: "Bereavement leave taken" },
  { key: "paternity_used", label: "Paternity leave taken" },
  { key: "compensatory_used", label: "Compensatory leave taken" },
  { key: "status", label: "Status" },
  { key: "action", label: "Actions" },
];

export const leaveTypes = [
  { title: "Annual Leave", key: "annual_used", slotName: "annual_used" },
  { title: "Sick Leave", key: "sick_used", slotName: "sick_used" },
  {
    title: "Maternity Leave",
    key: "maternity_used",
    slotName: "maternity_used",
  },
  {
    title: "Paternity Leave",
    key: "paternity_used",
    slotName: "paternity_used",
  },
  {
    title: "Compensatory Leave",
    key: "compensatory_used",
    slotName: "compensatory_used",
  },
  {
    title: "Bereavement Leave",
    key: "bereavement_used",
    slotName: "bereavement_used",
  },
];
