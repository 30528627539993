<template>
  <div class="w-full max-h-full">
    <div class="flex flex-col items-center justify-center   ">
      <div class="cooking-animated-svg">
        <img
          src='@/assets/cooking.gif'
          class="!w-full !h-full"
          alt=""
        />
      </div>
      <div class="text-center  sm:p-10 ">
          <h2 class="N800 ">Something is cooking</h2>
          <p class="P200 N800 H800 text-center text-base ">
            Prepare your taste buds for an extraordinary experience!
          </p>
        </div>
    </div>
  </div>
</template>



<style scoped lang="scss">

.cooking-animated-svg{
  width: clamp( 18.75rem, 14.743589743589745rem + 21.367521367521366vw, 25rem )


}

@media screen and (min-width: 768px) {
  .cooking-animated-svg{
    width: clamp( 28.125rem, 22.145833333333332rem + 4.166666666666666vw, 31.25rem );

  }
}



</style>
