<template>
    <div :class="isActive ? 'active' : 'notActive'" @click="isActive = !isActive" id="filter" class="BG0">
        <span class="SPC-MR-100"><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5665_2885)">
                    <path
                        d="M5.40002 3.09961H18.6C19.13 3.10119 19.6378 3.31241 20.0125 3.68714C20.3872 4.06187 20.5984 4.56966 20.6 5.09961V7.29961C20.5419 8.15866 20.1886 8.97121 19.6 9.59961L15.3 13.3996C14.9895 13.6978 14.7413 14.0546 14.5696 14.4494C14.398 14.8442 14.3063 15.2692 14.3 15.6996V19.9996C14.2866 20.3322 14.1988 20.6576 14.043 20.9518C13.8873 21.246 13.6676 21.5015 13.4 21.6996L12 22.5996C11.6939 22.7866 11.3439 22.8895 10.9853 22.8979C10.6267 22.9062 10.2722 22.8198 9.9577 22.6474C9.64319 22.4749 9.37977 22.2225 9.19406 21.9156C9.00835 21.6087 8.90692 21.2582 8.90002 20.8996V15.5996C8.84723 14.8358 8.56879 14.1049 8.10002 13.4996L4.30002 9.49961C3.78179 8.95437 3.46441 8.24908 3.40002 7.49961V5.19961C3.39068 4.9297 3.43529 4.66064 3.53124 4.40819C3.62719 4.15574 3.77255 3.92497 3.9588 3.72941C4.14506 3.53384 4.36847 3.3774 4.61594 3.26926C4.86341 3.16111 5.12998 3.10344 5.40002 3.09961V3.09961Z"
                        stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10.93 3.09961L6 10.9996" stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_5665_2885">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </span>

        <span>Filter</span>
        <span class="SPC-MR-100">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
        </span>
    </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

const isActive = ref(false)


</script>

<style scoped>
#filter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    cursor: pointer;
    padding-left: 3px;
    height: 42px;
    width: 150px;
    color: rgb(156, 156, 156);
}

.notActive {
    border: 1px solid #d8dae5;
}

.active {

    border: 1px solid #E47A53;
}
</style>