import gql from "graphql-tag";

export const GET_ACCESS_LEVEL_BYUSERID = gql`
  query GetUserLeaveAccessLevel($userId: ID!) {
    getUserLeaveAccessLevel(user_id: $userId) {
      id
      access_level {
        id
        name
        permissions
      }
    }
  }
`;

export const GET_ACCESS_LEVEL_USERS = gql`
  query GetLeaveAccessLevelById($accessLevelId: ID!) {
    getLeaveAccessLevelById(access_level_id: $accessLevelId) {
      id
      users {
        id
        full_name
        profile_image
      }
    }
  }
`;

export const GET_ACCESS_LEVELS = gql`
  query GetAllLeaveAccessLevels {
    getAllLeaveAccessLevels {
      id
      name
      permissions
      is_archived
      users {
        id
        full_name
      }
    }
  }
`;
