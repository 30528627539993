<template>
  <div>
    <div class="dearch_div mr-2 h-10" :class="className">
      <div class="input-div relative" :class="containerWidth">
        <div>
          <div class="flex absolute inset-y-0 pl-3 pointer-events-none">
            <img src="@/assets/search-normal.svg" class="w-4" alt="search-icon" />
          </div>
          <input :class="searchWidth" type="search" id="search" name="search" class="
              border border-[#d8dae5]
              hover:border-[#8F95B2]
              rounded-lg
              focus:outline-[#E47A53] focus:ring-2 focus:ring-[#F2BEAB]
              txt-field-style
              peer
              text-gray-900 text-sm
              block
              pl-10
              p-2.5
              dark:bg-gray-700
              dark:border-gray-600
              dark:placeholder-gray-400
              dark:text-white
              dark:focus:ring-[#F2BEAB]
              dark:focus:border[#d8dae5]
            " placeholder="Search" v-model="search" @keyup.enter="searchTable(search)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, watch } from "vue";

const props = defineProps({
  className: {
    type: String
  },
  searchWidth: {
    type: String
  },
  containerWidth: {
    type: String
  },
  clear: {
    type: Boolean || undefined
  }
});

let search = ref("");

const emit = defineEmits<{
  (e: "search", name: string): void;
  (e: "focus"): void;
  (e: "blur"): void;
}>();
const searchTable = (name: string) => {
  emit("search", name);
};
watch(
  search,
  () => {
    if (search.value === "") {
      emit("search", search.value);
    }
  },
  { deep: true, immediate: true }
);
</script>

<style scoped>
.dearch_div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.begin {
  justify-content: flex-start !important;
}

/*@mobile*/
@media (max-width: 670px) {
  input {
    width: 100%;
  }

  .dearch_div {
    justify-content: baseline !important;
    display: flex;
  }
}
</style>
