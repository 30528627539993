<template>
  <div class="flex gap-2 w-40 animate-pulse">
    <div class="flex flex-col py-2 gap-0.5">
      <span class="H400 N800 bg-slate-200 h-3 w-32"></span>
      <span class="P50 N600 bg-slate-200 h-3 w-20"></span>
    </div>
    <div class="flex flex-col py-2 gap-0.5">
      <span class="H400 N800 bg-slate-200 h-3 w-32"></span>
      <span class="P50 N600 bg-slate-200 h-3 w-20"></span>
    </div>
  </div>
</template>
