<template>
  <RouterLink :to="`${item?.route}`" class="">
    <div
      class="
        flex
        items-center
        gap-4
        rounded-lg
        cursor-pointer
        my-4
        py-3
        px-3
        hover:bg-[#FFF0EA]
        w-full
      "
      :class="$route.path === item?.route ? 'bg-[#FFF0EA]' : ''"
    >
      <TooltipComponent
        :text="item?.label"
        :position="'right'"
        v-if="!store.showAll"
      >
        <slot></slot>
      </TooltipComponent>
      <div v-else>
        <slot></slot>
      </div>
      <span
        class="
          text-base
          hide_view
          sm:hidden
          md:hidden
          lg:block
          xl:block
          whitespace-nowrap
        "
        :class="$route.path === item?.route ? 'O400' : ''"
        v-if="store.showAll"
        >{{ item?.label }}</span
      >
    </div>
  </RouterLink>
</template>
<script setup lang="ts">
import { PropType } from "vue";
import { useStore } from "@/store";
import TooltipComponent from "@/common/ui-kit/Tooltip/TooltipComponent.vue";

const store = useStore();
defineProps({
  item: {
    type: Object as PropType<{ label: string; route: string }>,
  },
});
</script>

<style scoped>
/* For 125% Zoom */
@media only screen and (min-device-width: 1024px) and (min-resolution: 120dpi) {
  .hide_view {
    display: none;
  }
}

/* For 150% Zoom */
@media only screen and (min-device-width: 1024px) and (min-resolution: 144dpi) {
  .hide_view {
    display: none;
  }
}

/* For 175% Zoom */
@media only screen and (min-device-width: 1024px) and (min-resolution: 168dpi) {
  .hide_view {
    display: none;
  }
}
</style>
