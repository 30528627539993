<template>
  <div class="toggle_box mb-1 flex flex-col">
    <div class="flex items-center justify-between">
      <span v-if="topLabel" class="font-medium text-gray-900 dark:text-gray-300">{{
        label
      }}</span>
      <span v-if="requireTag"
        class="bg-red-100 requiredSpan rounded-lg text-[#DD5928] text-xs px-1.5 py-1.5 mb-1.5 my-2 dark:bg-red-200 dark:text-red-900">
        Required
      </span>
    </div>
    <div class="flex items-center justify-between">
      <span v-if="sideLabel" class=" N800">{{
        label
      }}</span>
      <label :for="id" class="relative inline-flex items-center cursor-pointer w-max">
        <input type="checkbox" :id="id" :checked="isActive" v-model="isActive" class="sr-only peer" />
        <div data-cy="allowCompetency"
          :class="modelValue ? 'after:translate-x-full after:bg-white bg-[#DD5928]' : 'bg-gray-200'"
          class="w-11 h-6 rounded-full dark:bg-gray-700 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600">
        </div>
        <span class="ml-2 N800">{{ yesNno }}</span>
      </label>
      
    </div>

  </div>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue';


const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  requireTag: {
    type: Boolean,
    default: false,
  },
  disabledComponentState: {
    type: Boolean,
    default: false,
  },
  topLabel: {
    type: Boolean,
    default: true
  },
  sideLabel: {
    type: Boolean,
    default: false
  },
  yesNno: {
    type: String,
    default: ""
  }
});

const emit = defineEmits(['update:modelValue']);

const isActive = ref(props.modelValue);

watch(isActive, (value: boolean) => {
  emit('update:modelValue', value);
});


</script>
<style scoped>
.toggle_box {
  width: 100%;
}
</style>