<template>
    <svg width="30" height="30" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4"
            d="M12 22.2192C17.5228 22.2192 22 17.7421 22 12.2192C22 6.69639 17.5228 2.21924 12 2.21924C6.47715 2.21924 2 6.69639 2 12.2192C2 17.7421 6.47715 22.2192 12 22.2192Z"
            fill="#F9E0D7" />
        <path
            d="M15.5302 14.2292C15.3402 14.2292 15.1502 14.1592 15.0002 14.0092L12.0002 11.0092L9.00016 14.0092C8.71016 14.2992 8.23016 14.2992 7.94016 14.0092C7.65016 13.7192 7.65016 13.2392 7.94016 12.9492L11.4702 9.41916C11.7602 9.12916 12.2402 9.12916 12.5302 9.41916L16.0602 12.9492C16.3502 13.2392 16.3502 13.7192 16.0602 14.0092C15.9102 14.1592 15.7202 14.2292 15.5302 14.2292Z"
            fill="#B5461D" />
    </svg>
</template>
  