import { useAccessStore } from "@/store/storeLeavesPermissions";
import { HeaderType } from "@/types/componentTypes";

/**
 * A function that takes an array of table headers and a boolean flag and returns
 * a new array of headers. If the boolean flag is true, the function returns the
 * original array of headers. If the boolean flag is false, the function will
 * filter out any headers that have the label 'Action' or 'Actions' and return the
 * new array of headers.
 *
 * @param {HeaderType[]} headers - An array of table headers
 * @param {boolean} check - A boolean flag to control the functionality of the
 * function
 * @returns {HeaderType[]} - A new array of headers
 */
export const removeActionHeader = (headers: HeaderType[], check: boolean): HeaderType[] => {
  if (check) {
    return headers;
  }
  return headers.filter((header) => {
    return header.label !== "Action" && header.label !== "Actions";
  });
};

export const resetPermissionsStores = () => {
  useAccessStore().$reset();
};
