import { getToken } from "@/services/auth";
const BASE_URL = process.env.VUE_APP_FILE_UPLOAD_URL;

/**
 * A wrapper around the fetch API that adds the Authorization header
 * from the user's JWT token.
 *
 * @param {string} endpoint The endpoint to call.
 * @param {RequestInit} [options] The options to pass to the fetch API.
 * @returns {Promise<Response>} The response from the server.
 */
export const Api = async (endpoint: string, options: RequestInit = {}): Promise<Response> => {
  const token = getToken();
  const defaultHeaders = {
    Authorization: `${token}`,
  };
  const response = await fetch(`${BASE_URL}${endpoint}`, {
    ...options,
    headers: {
      ...defaultHeaders,
      ...(options.headers ?? {}),
    },
  });
  return response;
};
