<template>
  <template
    v-if="!env.VUE_APP_HOLIDAYS && leavesSettingsPerm.view_leave_holidays"
  >
    <div class="px-4">
      <!-- BreadCrum & Location -->
      <BreadCrumbsComponent :breadcrumbs="leaveBreadCrumbs" />

      <div class="BG0 px-5 py-2 pb-8 rounded-md">
        <h3 class="py-2">Leave Configuration</h3>

        <!-- Select country -->
        <KeepAlive>
          <div v-if="showSection === 'select-country'" class="mb-20 pb-2">
            <!-- stepper -->
            <StepperComponent :currentStep="1" :separator="1" :steps="steps" />
            <HorizontalLine />
            <div class="mt-5 w-80">
              <SelectFieldValue
                :showSearch="true"
                :showLabel="true"
                id="year"
                data-cy="year"
                label="Select Year"
                placeholder="Choose a year"
                :requireTag="true"
                :options="years.map((year) => year.toString())"
                v-model="leave.year"
              />
            </div>
            <div class="mt-5 w-80">
              <SelectFieldValue
                :showSearch="true"
                :showLabel="true"
                id="year"
                data-cy="country"
                label="Country"
                placeholder="Choose a country"
                :requireTag="true"
                :options="countries"
                v-model="leave.country"
              />
            </div>
            <div
              v-if="leavesSettingsPerm.manage_leave_holidays"
              class="flex items-center justify-end gap-4 pt-10"
            >
              <RouterLink
                data-cy="cancel"
                to="holidays-view"
                class="rounded-lg O400"
              >
                Cancel
              </RouterLink>
              <ButtonComponent
                data-cy="next"
                @click="getHolidays"
                :disabled="
                  leave.year.trim().length < 1 ||
                  leave.country.trim().length < 1
                "
              >
                Next
              </ButtonComponent>
            </div>
          </div>
        </KeepAlive>

        <!-- Select holidays section -->
        <KeepAlive>
          <div v-if="showSection === 'select-holidays'" class="mb-20 pb-2">
            <!-- stepper -->
            <StepperComponent :currentStep="2" :separator="2" :steps="steps" />
            <HorizontalLine />
            <LoaderIconVue v-if="!showContent" />
            <div v-else>
              <div class="flex items-center justify-between my-4">
                <div class="flex items-center">
                  <p class="flex items-center gap-3 ml-4 mr-2">
                    <input
                      data-cy="checkAll"
                      @click="importSelectAll"
                      type="checkbox"
                      class="w-4 h-4 cursor-pointer"
                      :style="{ accentColor: '#DD5928' }"
                    />
                    Import Holidays for
                    <strong>{{ selectedCountry }}</strong> in
                    <strong>{{ selectedYear }}</strong>
                  </p>
                </div>
                <div class="flex gap-2">
                  <ButtonComponent
                    data-cy="goBack"
                    @click="showSection = 'select-country'"
                    to="holidays--view"
                    variant="with_border"
                    class="
                      h-10
                      px-4
                      py-2
                      text-center
                      BG0
                      border border-gray-300
                      rounded
                      cursor-pointer
                      N700
                      hover:bg-gray-100 hover:text-gray-600
                    "
                  >
                    Change
                  </ButtonComponent>
                </div>
              </div>
              <TableComponentVue
                :overflowXAuto="false"
                :headers="headers"
                :items="ListHolidays"
                :itemsPerPage="9"
              >
                <template #title="slotProps">
                  <span class="flex items-center gap-3">
                    <input
                      data-cy="checkbox"
                      type="checkbox"
                      class="w-4 h-4 cursor-pointer"
                      :style="{ accentColor: '#DD5928' }"
                      @click="importRowSelection(slotProps.item as ListHoliday)"
                      :checked="(slotProps.item as ListHoliday)?.selected"
                    />
                    {{ (slotProps.item as LeaveHolidayImport)["title"] }}</span
                  >
                </template>
                <template #start_day="slotProps">
                  <span class="flex items-center">
                    {{
                      formatDateApi(
                        (slotProps.item as LeaveHolidayImport)["start_day"]
                      )
                    }}
                  </span>
                </template>
                <template #end_day="slotProps">
                  <span class="flex items-center">
                    {{
                      getDayOfWeekApi(
                        (slotProps.item as LeaveHolidayImport)["start_day"]
                      )
                    }}
                  </span>
                </template>
                <template #holiday_type>
                  <span class="flex items-center"> Public </span>
                </template>
              </TableComponentVue>
              <p class="flex justify-start pt-5">
                You selected {{ selectedTableData.length }} {{ selectedTerm }}
              </p>
              <div class="flex justify-between pt-10">
                <span
                  class="pr-10 cursor-pointer N700"
                  @click="showSection = 'select-country'"
                >
                  Back
                </span>
                <div
                  v-if="leavesSettingsPerm.manage_leave_holidays"
                  class="flex items-center gap-4"
                >
                  <RouterLink
                    data-cy="cancel"
                    to="holidays-view"
                    class="rounded-lg O400"
                  >
                    Cancel
                  </RouterLink>
                  <ButtonComponent
                    data-cy="next"
                    @click="showSection = 'confirm-import'"
                    :disabled="selectedTableData.length < 1"
                  >
                    Next
                  </ButtonComponent>
                </div>
              </div>
            </div>
          </div>
        </KeepAlive>

        <!-- Confirm Imports -->
        <div v-if="showSection === 'confirm-import'" class="mb-20 pb-2">
          <!-- stepper -->
          <StepperComponent :currentStep="3" :separator="3" :steps="steps" />
          <HorizontalLine />
          <div class="flex items-center">
            <p class="pt-5 mr-2">
              These are the holidays you've chosen for
              <strong>{{ selectedCountry }}</strong> in the year
              <strong>{{ selectedYear }}</strong
              >.
            </p>
          </div>
          <div
            class="flex items-center justify-center m-5"
            v-if="selectedTableData.length === 0"
          >
            <div class="emptyManageLoan">
              <EmptyManageLeaveTable />
              <p class="emptyDesc">No import holidays selected.</p>
            </div>
          </div>
          <div class="p-5 BG0 rounded-lg formsTable" v-else>
            <div class="manageFormsTable">
              <ol
                v-for="(item, index) in selectedTableData"
                :key="index"
                height="64px"
              >
                <li class="flex gap-3 py-4">
                  <strong>{{ index + 1 }}. {{ item.title }},</strong>
                  {{ item.start_day }}
                </li>
              </ol>
            </div>
          </div>

          <div class="flex justify-between pt-10">
            <span
              class="pr-10 cursor-pointer N700"
              @click="showSection = 'select-holidays'"
            >
              Back
            </span>
            <div
              v-if="leavesSettingsPerm.manage_leave_holidays"
              class="flex items-center gap-4"
            >
              <RouterLink
                data-cy="cancel"
                to="holidays-view"
                class="rounded-lg O400"
              >
                Cancel
              </RouterLink>
              <ButtonComponent
                data-cy="submit"
                @click="() => addLeaveHoliday.mutate()"
                :disabled="selectedTableData.length < 1"
              >
                Import
              </ButtonComponent>
            </div>
          </div>
          <p class="flex justify-end pt-5">
            Import {{ selectedTableData.length }} selected {{ selectedTerm }}
          </p>
        </div>
      </div>
    </div>

    <AlertComponent :message-block="messageBlock" />
  </template>
  <div v-else-if="env.VUE_APP_LEAVE_MANAGER_APPROVAL">
    <VersionDisplay class="" />
  </div>
  <div class="flex flex-col" v-else>
    <NotAuthorised class="" />
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, computed, KeepAlive } from "vue";
import { useRouter } from "vue-router";
import { useAccessStore } from "@/store/storeLeavesPermissions";
import {
  formatDateApi,
  getDayOfWeekApi,
  generateYears,
  generateListOfCountries,
} from "@/helpers/leaveRequestFunctions";
import { messageBlock } from "@/helpers/book-leaves/leaveRequests";
import {
  LeaveHolidayImport,
  ListHoliday,
} from "@/types/leave-configuration/leaveHolidayImport";
import EmptyManageLeaveTable from "@/common/ui-kit/EmptyState/EmptyStateComponent.vue";
import SelectFieldValue from "@/common/ui-kit/Inputs/SelectField.vue";
import BreadCrumbsComponent from "@/common/ui-kit/bread-crumbs/BreadCrumbsComponent.vue";
import ButtonComponent from "@/common/ui-kit/button/ButtonComponent.vue";
import StepperComponent from "@/common/ui-kit/responsiveStepper/StepperListView.vue";
import TableComponentVue from "@/common/ui-kit/table/TableComponent.vue";
import HorizontalLine from "@/common/ui-kit/HorizontalLine.vue";
import AlertComponent from "@/common/ui-kit/AlertComponent.vue";
import VersionDisplay from "@/common/components/HelloWorld.vue";
import LoaderIconVue from "@/common/ui-kit/loader/LoaderIcon.vue";
import { useQuery, useMutation } from "@vue/apollo-composable";
import {
  ADD_IMPORT_HOLIDAYS,
  IMPORT_HOLIDAYS,
} from "../../graphql/mutations/leave-configuration/setHolidays";
import { LIST_HOLIDAYS } from "../../graphql/queries/leave-configuration/getHolidays";
import NotAuthorised from "@/common/components/NotAuthorized.vue";
import {
  sucessMessages,
  infoMessages,
  timer,
  errorMessages,
} from "@/constants";
// Constants
const env = process.env;

// Reactive Variables
const Access = useAccessStore();
const permissions = Access.permissions?.settings?.leave_management;
const leavesSettingsPerm = permissions ?? {};

const router = useRouter();
const showContent = ref(false);
const ListHolidays = ref<ListHoliday[]>([]);
const startYear = "2019";
const years = ref(generateYears(startYear));
const countries = ref([]);
const showSection = ref("select-country");
generateListOfCountries().then((data) => {
  countries.value = data;
});

const leaveBreadCrumbs = ref([
  { label: "Home", to: "/home" },
  { label: "Leave configuration", to: "/leave-type" },
  { label: "Holidays", to: "/holidays-view" },
  { label: "Import", to: "/holidays-import-view" },
]);

const headers = [
  { key: "title", label: "Holiday name" },
  { key: "start_day", label: "Date(s)" },
  { key: "end_day", label: "Day(s)" },
  { key: "holiday_type", label: "Holiday type" },
];

const steps = [
  { number: 1, text: "Country", isOK: false },
  { number: 2, text: "Holidays", isOK: false },
  { number: 3, text: "Confirm", isOK: false },
];

const leave: LeaveHolidayImport = reactive({
  id: "",
  title: "",
  description: "",
  year: "",
  country: "",
  start_day: "",
  end_day: "",
  selected: false,
});

// computed properties
const selectedCountry = computed(() => {
  return leave.country;
});

const selectedYear = computed(() => {
  return leave.year;
});

const selectedTerm = computed(() => {
  const count = selectedTableData.value.length;
  if (count === 0) {
    return "holidays";
  } else if (count === 1) {
    return "holiday";
  } else {
    return "holidays";
  }
});

const selectedHolidays = computed(() => {
  return ListHolidays.value
    .filter((item: ListHoliday) => item.selected)
    .map((holiday: ListHoliday) => ({
      title: holiday.title,
      start_day: holiday.start_day,
      country: holiday.country,
    }));
});

const selectedTableData = computed(() => {
  return selectedHolidays.value.slice();
});

// functions
const importSelectAll = () => {
  const allSelected = ListHolidays.value.every((item) => item.selected);
  ListHolidays.value.forEach((item) => {
    item.selected = !allSelected;
  });
};

const importRowSelection = (item: ListHoliday) => {
  item.selected = !item.selected;
};

// Mutations & Queries
const importHolidays = useMutation(IMPORT_HOLIDAYS);

const getHolidays = () => {
  importHolidays
    .mutate({
      data: {
        year: leave.year,
        country: leave.country,
      },
    })
    .then((response) => {
      const responseData = response?.data as
        | { importHolidays: ListHoliday[] }
        | undefined;
      const holidays = responseData?.importHolidays;

      if (holidays) {
        ListHolidays.value = holidays;
        showSection.value = "select-holidays";
        showContent.value = false;

        setTimeout(() => {
          showContent.value = true;
        }, timer.timerThree);
      } else {
        showSection.value = "select-country";
      }
    })
    .catch((error) => {
      messageBlock.open = true;
      messageBlock.severity = "warning";
      messageBlock.message =
        error.message.length <= 100
          ? error.message
          : infoMessages.contactSupport;

      setTimeout(() => {
        messageBlock.open = false;
      }, timer.timerFive);
    });
};

const addLeaveHoliday = useMutation(ADD_IMPORT_HOLIDAYS, () => ({
  variables: {
    data: selectedHolidays.value,
  },
}));

const { refetch: refetchLeavesHolidays } = useQuery(LIST_HOLIDAYS);
addLeaveHoliday.onDone(() => {
  refetchLeavesHolidays();
  messageBlock.open = true;
  messageBlock.severity = "success";
  close();
  messageBlock.message = sucessMessages.leaveHolidayImported;
  setTimeout(() => {
    messageBlock.open = false;
    router.push("/holidays-view");
  }, timer.timerFive);
});

addLeaveHoliday.onError(() => {
  messageBlock.open = true;
  messageBlock.severity = "error";
  messageBlock.message = errorMessages.leaveHolidayImportedFailed;
  close();
  setTimeout(() => {
    messageBlock.open = false;
    router.push("/holidays-view");
  }, timer.timerFive);
});
</script>
