import gql from "graphql-tag";

export const CREATE_HOLIDAY = gql`
  mutation CreateHoliday($data: CreateHolidayInput!) {
    createHoliday(data: $data) {
      id
      title
      description
      start_day
      end_day
      is_archived
      country
    }
  }
`;

export const EDIT_HOLIDAYS = gql`
  mutation UpdateHoliday($data: UpdateHolidayInput!) {
    updateHoliday(data: $data) {
      id
      title
      description
      start_day
      end_day
      is_archived
      country
    }
  }
`;

export const SOFT_DELETE_HOLIDAY = gql`
  mutation ArchiveHoliday($archiveHolidayId: ID!, $isArchived: Boolean!) {
    archiveHoliday(id: $archiveHolidayId, is_archived: $isArchived) {
      id
      title
      description
      start_day
      end_day
      is_archived
      country
    }
  }
`;

export const REMOVE_LEAVE_HOLIDAY_STATUS = gql`
  mutation DeleteHoliday($deleteHolidayId: ID!) {
    deleteHoliday(id: $deleteHolidayId) {
      id
      title
      description
      start_day
      end_day
      is_archived
      country
    }
  }
`;

export const ADD_IMPORT_HOLIDAYS = gql`
  mutation CreateBatchHolidays($data: [CreateBatchHolidaysInput]!) {
    createBatchHolidays(data: $data) {
      count
    }
  }
`;

export const IMPORT_HOLIDAYS = gql`
  mutation ImportHolidays($data: ImportHolidaysInput!) {
    importHolidays(data: $data) {
      title
      start_day
      country
    }
  }
`;
