import { ref, Ref, watch } from "vue";

// Function to watch manage and view checkboxes
export function watchManageAndView(
  manageRef: Ref<boolean>,
  viewRef: Ref<boolean>
) {
  watch(manageRef, (value) => {
    if (value) {
      viewRef.value = true;
    }
  });
}

// Define settings initialize
export const viewLeaveTypesChecked = ref(false);
export const manageLeaveTypesChecked = ref(false);
export const viewLeavePolicyChecked = ref(false);
export const manageLeavePolicyChecked = ref(false);
export const viewLeaveHolidaysChecked = ref(false);
export const manageLeaveHolidaysChecked = ref(false);
export const viewLeaveTasksChecked = ref(false);
export const manageLeaveTasksChecked = ref(false);
export const viewAccessLevelChecked = ref(false);
export const manageAccessLevelChecked = ref(false);

// Define dashboard initialize
export const viewLeaveRequestsChecked = ref(false);
export const manageLeaveRequestsChecked = ref(false);
export const viewLeaveApproversChecked = ref(false);
export const manageLeaveApproversChecked = ref(false);
export const viewLeaveValidatorChecked = ref(false);
export const manageLeaveValidatorChecked = ref(false);
export const viewEmployeePlannerChecked = ref(false);
export const manageEmployeePlannerChecked = ref(false);
export const viewManagerPlannerChecked = ref(false);
export const manageManagerPlannerChecked = ref(false);
export const viewManagerDashboardChecked = ref(false);
export const manageManagerDashboardChecked = ref(false);
export const viewHRDashboardChecked = ref(false);
export const manageHRDashboardChecked = ref(false);
export const viewHREmployeeInfoChecked = ref(false);
export const manageHREmployeeInfoChecked = ref(false);

// Define permission names
export const permissionNames: { [key: string]: string } = {
  view_leave_approvers: "View Leave Approvers",
  manage_leave_approvers: "Manage Leave Approvers",
  view_leaves: "View Leaves",
  manage_leaves: "Manage Leaves",
  view_leave_type: "View Leave Type",
  view_leave_tasks: "View Leave Tasks",
  manage_leave_type: "Manage Leave Type",
  view_hr_dashboard: "View HR Dashboard",
  view_hr_employee_info: "View HR Employee Info",
  view_leave_policy: "View Leave Policy",
  manage_leave_tasks: "Manage Leave Tasks",
  manage_hr_dashboard: "Manage HR Dashboard",
  manage_hr_employee_info: "Manage HR Employee Info",
  manage_leave_policy: "Manage Leave Policy",
  view_leave_holidays: "View Leave Holidays",
  manage_leave_holidays: "Manage Leave Holidays",
  view_leaves_validator: "View Leaves Validator",
  view_manager_dashboard: "View Manager Dashboard",
  manage_leaves_validator: "Manage Leaves Validator",
  view_leave_access_level: "View Leave Access Level",
  manage_manager_dashboard: "Manage Manager Dashboard",
  manage_leave_access_level: "Manage Leave Access Level",
  view_manager_leave_planner: "View Manager Leave Planner",
  view_employee_leave_planner: "View Employee Leave Planner",
  manage_manager_leave_planner: "Manage Manager Leave Planner",
  manage_employee_leave_planner: "Manage Employee Leave Planner",
};

// Watch manage and view checkbox
watchManageAndView(manageLeaveTypesChecked, viewLeaveTypesChecked);
watchManageAndView(manageLeavePolicyChecked, viewLeavePolicyChecked);
watchManageAndView(manageLeaveHolidaysChecked, viewLeaveHolidaysChecked);
watchManageAndView(manageLeaveTasksChecked, viewLeaveTasksChecked);
watchManageAndView(manageAccessLevelChecked, viewAccessLevelChecked);
watchManageAndView(manageManagerDashboardChecked, viewManagerDashboardChecked);
watchManageAndView(manageHRDashboardChecked, viewHRDashboardChecked);
watchManageAndView(manageHREmployeeInfoChecked, viewHREmployeeInfoChecked);
watchManageAndView(manageManagerPlannerChecked, viewManagerPlannerChecked);
watchManageAndView(manageEmployeePlannerChecked, viewEmployeePlannerChecked);
watchManageAndView(manageLeaveApproversChecked, viewLeaveApproversChecked);
watchManageAndView(manageLeaveRequestsChecked, viewLeaveRequestsChecked);
watchManageAndView(manageLeaveValidatorChecked, viewLeaveValidatorChecked);

export const resetCheckboxes = () => {
  const checkboxes = [
    viewLeaveTypesChecked,
    manageLeaveTypesChecked,
    viewLeavePolicyChecked,
    manageLeavePolicyChecked,
    viewLeaveHolidaysChecked,
    manageLeaveHolidaysChecked,
    viewLeaveTasksChecked,
    manageLeaveTasksChecked,
    viewAccessLevelChecked,
    manageAccessLevelChecked,
    viewLeaveRequestsChecked,
    manageLeaveRequestsChecked,
    viewLeaveApproversChecked,
    manageLeaveApproversChecked,
    viewLeaveValidatorChecked,
    manageLeaveValidatorChecked,
    viewEmployeePlannerChecked,
    manageEmployeePlannerChecked,
    viewManagerPlannerChecked,
    manageManagerPlannerChecked,
    viewManagerDashboardChecked,
    manageManagerDashboardChecked,
    viewHRDashboardChecked,
    manageHRDashboardChecked,
    viewHREmployeeInfoChecked,
    manageHREmployeeInfoChecked,
  ];

  checkboxes.forEach((checkbox) => (checkbox.value = false));
};
