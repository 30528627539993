import gql from "graphql-tag";

export const GET_HR_REQUESTS = gql`
  query ListHrLeaveRequests($data: LeaveRequestInput) {
    listHrLeaveRequests(data: $data) {
      id
      status
      days_off
      carry_over_used
      user_id
      manager_id
      manager_approved
      days
      year
      file
      first_last_days
      leave_type
      user {
        id
        full_name
        profile_image
      }
    }
  }
`;

export const GET_TOTAL_HR_REQUESTS = gql`
  query CountHrLeaveRequests($data: leaveCountInput) {
    countHrLeaveRequests(data: $data) {
      count
      status
    }
  }
`;

export const GET_HR_EMPLOYEES_LISTS = gql`
  query ListHrLeavesInfo($data: LeavePaginationInput, $onLeave: Boolean) {
    listHrLeavesInfo(data: $data, onLeave: $onLeave) {
      on_site_count
      users_list {
        user_id
        user {
          id
          full_name
          profile_image
          employeeInfo {
            organization {
              country
            }
          }
        }
        leave_types_used {
          days_used
          leave_type {
            title
            id
          }
        }
        accrued_days
        available_days
        max_accrual
        hr_addition
        hr_deduction
        carry_over_days
        annual_days_used
        on_leave
      }
    }
  }
`;

export const GET_HR_EMPLOYEES_OVERVIEW = gql`
  query On_leave_count($data: hrLeaveCountInput) {
    countHrLeavesInfo(data: $data) {
      on_leave_count {
        count
        leave_type {
          id
          title
          description
        }
      }
    }
  }
`;

export const GET_EMPLOYEE_INFO = gql`
  query GetLeavesEmployeeActiveInfo($userId: ID!) {
    getLeavesEmployeeActiveInfo(user_id: $userId) {
      user_id
      users {
        full_name
        profile_image
        email
      }
      position {
        position_name
      }
      organization {
        country
        name
      }
    }
  }
`;

export const GET_LIST_OF_EMPLOYEES = gql`
  query ListLeaveEmployees {
    listLeaveEmployees {
      id
      full_name
    }
  }
`;

export const GET_LIST_OF_EMPLOYEES_BY_ORGANIZATION = gql`
  query ListLeaveEmployees($data: LeavePaginationInput) {
    listLeaveEmployees(data: $data) {
      id
      full_name
    }
  }
`;

export const GET_LIST_OF_APPROVERS = gql`
  query ListLeaveApprovers {
    listLeaveApprovers {
      id
      organization_id
      user_id
      description
      user {
        full_name
      }
      organization {
        name
      }
    }
  }
`;

export const GET_TOUCH_POINT_COMMENTS = gql`
  query ListCommentsById($requestId: ID!) {
    listCommentsById(request_id: $requestId) {
      id
      message
      user_id
      timestamp
      booked_leaves_id
      user {
        full_name
        profile_image
      }
    }
  }
`;

export const GET_LEAVES_CSV_OVERVIEW = gql`
  query GenerateLeavesCsvByDate(
    $startDate: DateTime!
    $endDate: DateTime!
    $organizationId: ID
  ) {
    generateLeavesCsvByDate(
      start_date: $startDate
      end_date: $endDate
      organization_id: $organizationId
    ) {
      type_category
      employee_count
      hours_lost
    }
  }
`;

export const GET_EMPLOYEE_LEAVES_CSV = gql`
  query ExportDaysUsedByUser(
    $startDate: DateTime!
    $endDate: DateTime!
    $organizationId: ID
  ) {
    exportDaysUsedByUser(
      start_date: $startDate
      end_date: $endDate
      organization_id: $organizationId
    ) {
      user_name
      leave_types_used {
        leave_type
        hours_used
      }
    }
  }
`;
