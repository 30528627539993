<template>
  <input type="radio" :id="fieldId" class="mr-2 accent-[#DD5928] w-5 h-5 hover:cursor-pointer" :checked="checked"
    @change="toggleRadio" />
  <label :for="fieldId" class="N800 P200 ml-2 flex-nowrap">
    {{ label }}
  </label>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  fieldId: {
    type: String,
    required: true,
  },
  checked: {
    type: Boolean,
  },
});

const emit = defineEmits(["update:checked", "change"]);

const toggleRadio = () => {
  emit("update:checked", !props.checked);
  emit("change", !props.checked);
};
</script>
